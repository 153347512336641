.connect-info {
  width: calc(100% - 24px);
  max-width: 700px;

  .content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    svg {
      fill: #5E8E3E;
      margin-right: 12px;
    }

    span {
      color: #2D3748;
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
    }
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }

  form {
    margin-top: 48px;
    text-align: left;

    .form-label {
      text-transform: capitalize;
    }

    .input-group-text {
      border: none;
    }

    .action-buttons {
      margin-top: 36px;
    }

    .need-help {
      display: flex;
      justify-content: center;
      margin-top: 36px;

      a {
        color: #2D3748;
        font-size: 14px;
        font-weight: 500;
        font-style: italic;
        line-height: 20px;
        text-decoration: none;
      }
    }

    .need-help--extra {
      a {
        font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .connecting {
    display: inline-block;
    margin-top: 60px;
    position: relative;
    padding: 25px;

    .connecting-wrapper {
      border: 10px solid #4FD1C50F;
      border-radius: 50%;
      padding: 15px;

      .connecting-content {
        background: #F1FBFA5C;
        border: 18px solid #4FD1C51F;
        border-radius: 50%;
        width: 220px;
        height: 220px;
        position: relative;

        svg {
          fill: #4FD1C5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 529px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .connecting-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 10px solid transparent;
      border-bottom-color: #4FD1C512;
      border-top-color: #4FD1C512;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotate 3s linear infinite;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

