.billing-information {
  width: 100%;
  margin: 50px auto;

  h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.4;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .toggle-switch {
          margin-right: 17px;
      }
  }

  .billing-info-col {
      position: relative;
      margin-bottom: 33px;

      .form-control {
          margin-top: 10px;
          padding: 15px 26px;
          border: 1px solid #09183D;
      }

      .form-select {
          margin-top: 10px;
          padding: 12.5px 26px;
          border: 1px solid #09183D;
          border-radius: 8px;
      }

      .form-label {
          position: absolute;
          left: 29px;
          background-color: #fff;
          padding: 0 10px;
      }
  }

  .action-buttons {
      width: 290px;
      margin: 66px auto 0;

      button {
          width: 100%;
          height: 38px;
          background: #4FD1C5;
          border-radius: 8px;
          padding: 10px;
          font-size: 13px;
          line-height: 1.4;
          text-align: center;
          color: #fff;
          box-shadow: none !important;
      }
  }
}
