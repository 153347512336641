.file-input {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 141px;

  .file-name {
    display: none;
  }

  .file {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;

    &:hover + label {
      border-color: #101010;
    }
  }

  img {
    max-width: 120px;
  }

  label {
    background: #FCFDFD;
    border: 1px dashed #9F9F9F;
    border-radius: 19px;
    padding: 25px 70px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      fill: #9B9B9B45;
    }
  }

  p {
    color: #101010;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .upload-button {
    margin: 30px auto 0;
  }
}