.publicdash-container {
    width: 100%;
    margin: 50px auto;
    .file-input {
        img {
            max-height: 100%;
        }
    }

    .form-label {
        font-size: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #A0AEC0;
        margin-bottom: 5px;
    }

    .input-group {
        border: 1px solid #A0AEC0;
        box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .form-control {
            border: 0;
            box-shadow: none;
            padding-left: 0;
            font-size: 14px;
            font-weight: 700;
            z-index: 0;
        }

        .input-group-text {
            background-color: transparent;
            border: 0;
            padding-left: 20px;
            padding-right: 12px;
        }

        button {
            padding: 0 42px;
            font-weight: 700;
            color: #4FD1C5;
            background: #F1FBFA;
            border: 1px solid #4FD1C5;
            box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.05);
            border-radius: 0px 10px 10px 0px;
            margin: -1px;
            text-transform: uppercase;
        }
    }

    .dash-preview-container {
        h2 {
            font-size: 28px;
            font-weight: 700;
        }
    }
    .file-input p {
        font-size: 14px;
    }
    .logo-button {
        height: 50px;
        padding: 0 42px;
        font-weight: 700;
        color: #4FD1C5;
        background: #F1FBFA;
        border: 1px solid #4FD1C5;
        box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.05);
        border-radius: 10px 10px 10px 10px;
        margin: -1px;
        margin-bottom: 16px;
        margin-top: 12px;
        text-transform: uppercase;
        width: 100%;
    }
}