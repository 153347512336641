.integration-card {
	border: 2px solid #abb5c4;
	height: 100%;

	&__content {
		padding: 30px 40px 5px 30px;
	}

	&__image-container img {
		object-fit: contain;
	}

	&__active-badge {
		background-color: #77cfc6;
		font-size: 13px;
		letter-spacing: 0.75px;
		padding: 4px 15px 1px 15px;
		right: 26px;
		top: 30px;
	}
	&__platform-name {
		font-size: 21px !important;
		max-width: 250px;
	}

	&__platform-description {
		color: #000;
		font-size: 13px;
		max-width: 250px;
		text-overflow: ellipsis;
	}

	&__action {
		border-top: 2px solid #eaeaea;
		cursor: pointer;
		font-size: 13px;
		letter-spacing: 0.75px;
		padding: 16px 0 13px 0;
		user-select: none;
		color: #77cfc6;
	}

	&__platform-powered {
		font-size: 11px !important;
		font-style: italic;
	}

	&--inactive {
		border-color: #eaeaea;
	}

	&--active {
		border-color: #77cfc6;
	}
	&--active .integration-card__action {
		border-top: 2px solid #77cfc6;
	}

	&--unavailable {
		border-color: #eaeaea;
	}

	&__disabled {
		color: lightgrey;
		cursor: initial;
	}
}
