.custom-table {
	width: 100%;
	margin: auto 0;
	letter-spacing: 0.5px;
	margin-top: 5px;

	.custom-table__header--centered-bold {
		text-align: right;
	}

	&__header {
		tr {
			border: none !important;
		}

		th {
			color: black;
			font-size: 0.85rem;
			text-transform: uppercase;
			border: none;
			padding-right: 0;
			letter-spacing: 1px;
		}
	}

	&__row {
		th,
		td {
			padding-bottom: 10px;
			border: none;
		}
	}

	&__circle-button {
		background-color: #77cfc6;
		border: none;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 4px 6px;
		border-radius: 50%;
		color: white;

		&.greyed-out {
			background-color: rgba(242, 240, 240, 0.699) !important;
			color: #77cfc6 !important;
		}

		&:hover {
			background-color: #77cfc6 !important;
			color: white !important;
			box-shadow: none;
		}

		&:active {
			background-color: rgb(236, 233, 233);
			color: #77cfc6;
			box-shadow: none;
		}
		&:disabled {
			background-color: rgba(181, 181, 181, 0.8);
			color: rgb(67, 67, 67);
		}
	}

	.greyed-out {
		color: rgba(181, 181, 181, 0.8);
		font-weight: 100;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__trees-count {
		margin: 0 5px;
		font-weight: bold;
	}

	.custom-table__circle-button:active {
		background-color: #77cfc6 !important;
	}
}
