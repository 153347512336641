.order-filters {
	.form-label {
		font-size: 14px;
		font-weight: 600;
		line-height: 32px;
		color: #fff;
	}

	.form-control,
	.form-select {
		background: #ffffff36;
		border: 1px solid #ffffff47;
		border-radius: 11px;
		color: #fff;
		padding: 15px 20px;
		font-size: 14px;
		line-height: 1.4;

		option {
			color: #000;
		}
	}

	.react-datepicker-wrapper input {
		background: #ffffff36;
		border: 1px solid #ffffff47;
		border-radius: 11px;
		color: #fff;
		font-size: 14px;
		line-height: 1.4;
		padding: 15px 20px;
		display: block;
		width: 100%;
		appearance: none;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		&:focus {
			box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
			outline: 0;
		}
	}

	.form-switch {
		position: relative;
		padding-right: 55px;
		padding-left: 0;
		margin-bottom: 0;

		.form-check-label {
			font-size: 14px;
			line-height: 32px;
		}

		.form-check-input {
			position: absolute;
			right: 0;
			margin-top: 9px;
			margin-left: 0;

			&:checked {
				background-color: #1aaa90;
				border-color: #1aaa90;
			}
		}
	}

	.export-button {
		display: flex;
		align-items: center;
		margin-left: 40px;
		margin-right: 1rem;

		a {
			color: #fff;
		}

		span {
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			line-height: 32px;
			margin-right: 20px;
		}

		svg {
			stroke: #fff;
		}
	}

	@media screen and (max-width: 767px) {
		color: #2d3748;

		.form-label {
			color: #000;
		}

		.form-control {
			background: #eaeaea;
			color: #000;
		}

		.export-button {
			svg {
				stroke: #000;
			}
		}
	}
}
