.header {
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
  border: 1.5px solid #FFFFFF;
  box-shadow: 0 7px 23px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(21px);
  border-radius: 15px;
  padding: 20px 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .logo {
    cursor: pointer;
  }

  .navbar {
    padding: 0;

    .navbar-brand {
      padding: 0;
      margin: 0;

      svg {
        width: 90px;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          display: flex;
          align-items: center;
          color: #2D3748;
          margin-left: 30px;
          padding: 0;

          svg {
            margin-right: 4px;
          }

          span {
            font-size: 10px;
            font-weight: 700;
            line-height: 15px;
          }

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      @media screen and (max-width: 991px) {
        .navbar-nav {
          margin-top: 16px;
          border-top: 1px solid #CECECE;

          .nav-link {
            padding: 8px 0;
            margin-left: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    position: sticky;
    top: 10px;
    transform: none;
    left: 0;
  }
}