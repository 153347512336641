.settings-sidebar-container {
  z-index: 10;

  .settings-sidebar {
    background-color: #FDFDFD;
    border-right: 2px solid #F4F4F4;
    width: 300px;
    padding: 50px 35px 25px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .logo {
      margin-bottom: 100px;
      display: flex;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    a.menu-group-title {
      line-height: 25px;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 13px;
      color: #000000;
      text-decoration: none;
      display: none;
      margin-bottom: 20px;

      svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
      }

      @media (max-width: 768px) {
        display: inline-flex;
      }
    }

    .menu-group {
      margin-bottom: 43px;

      .menu-group-title {
        line-height: 25px;
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 13px;

        svg {
          margin-right: 8px;
        }
      }

      .menu-item {
        a {
          font-size: 14px;
          line-height: 19.6px;
          color: #A0AEC0;
          font-weight: 700;
          padding: 7.5px 20px;
          background-color: #FFFFFF;
          margin-bottom: 15px;
          border-radius: 8px;
          text-decoration: none;
          display: flex;
          text-transform: uppercase;
        }

        &.active a {
          background-color: #F1FBFA;
          color: #000000;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .btn-logout {
      display: flex;
      color: #000000;
      text-decoration: none;
      font-size: 12px;
      line-height: 17px;
      align-items: center;
      margin: 0 auto;
      font-weight: 600;

      svg {
        margin-right: 8px;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 240px;
    }
  }

  .settings-sidebar-overlay {
    display: none;
    background-color: transparent;
    flex-grow: 1;
  }

  @media screen and (max-width: 991px) {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;

    .settings-sidebar .logo {
      margin-bottom: 30px;
    }

    &.active {
      display: flex;

      .settings-sidebar-overlay {
        display: flex;
      }
    }
  }
}
