.Cient-Dashboard__cream-container {
  background-color: #EDE6CC;
  color: #12342E;
  border-radius: 0px 0px 60px 60px;
  padding: 36px 32px;

  @media screen and (min-width: 768px){
    padding: 36px 76px;
  }
}
