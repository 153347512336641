.manage-keys {
    width: 100%;
    margin: 50px auto;
}

button.button-submit {
    /* ...otros estilos existentes... */
    font-size: 13px;
    line-height: 18.2px;
    padding: 10px 20px;
    background-color: #4FD1C5;
    border: none;
    border-radius: 8px;
}

button.button-submit:hover {
    background-color: #4FD1C5;
    opacity: 0.75 !important;
}

.masked-api-key {
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    word-spacing: 5px;
    padding: 5px;
    color: #4FD1C5;
    text-align: center;
    caret-color: transparent;
    text-align: center;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }