.dash-preview {
    position: relative;

    .desktop-bg {
        width: 100%;
    }

    .page-inner {
        position: absolute;
        left: 3.05%;
        top: 13.2%;
        right: 3.05%;
        bottom: 0;
        background: url(../../assets/images/dashboard/background_2.png) top left no-repeat;

        > .logo {
            width: 10%;
            display: block;
            position: absolute;
            left: 8%;
            top: 7%;

            svg {
                fill: #FFF;
                max-width: 100%;
            }
        }

        .nav-link {
            padding: 0;
            color: white;
            font-size: 0.8vw;
            font-weight: 700;
            position: absolute;
            right: 5%;
            top: 7%;
        }

        .content {
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .logo-link {
                display: block;
                width: 15%;

                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
            }

            h2 {
                font-size: 1.9vw;
                width: 40%;
                font-weight: 700;
                color: #fff;
                margin: 1.1vw 0;
            }

            p {
                margin-bottom: 0;
                font-size: 1.25vw;
                letter-spacing: 0.1em;
                color: #fff;
                line-height: 1;
            }
        }
        .nav-link {
            font-family: "Inter", sans-serif !important;
            color:white;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
    }
}
