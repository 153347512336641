.first-enter {
	.modal-content {
		background-color: #e6dfc6;
		border-radius: 20px;
		border: none;

		.modal-header {
			flex-flow: column;
			padding: 0;
			text-align: center;
			border: none;
			position: relative;

			.btn-close {
				position: absolute;
				top: 30px;
				right: 30px;
			}

			.modal-title {
				margin-top: 36px;
				margin-bottom: 10px;
				font-size: 30px;
				line-height: 32px;
				font-weight: 700;
				color: #000;
				font-family: Lora, serif !important;
			}

			svg {
				bottom: -50px;
				position: absolute;
				left: -20px;
				width: 300px;
				height: auto;
				path {
					stroke: #12342e;
				}
			}

			p {
				font-size: 22px;
				line-height: 32px;
				font-weight: 700;
				font-family: Lora, serif !important;
				color: #000;
				margin-bottom: 30px;
				padding: 0 28px;
			}

			.enter-dashboard {
				padding: 14px 79px;
				background-color: #12342e;
				border-radius: 3px;
				color: #ffffff;
				font-size: 18px;
				line-height: 32px;
				margin-bottom: 32px;
				z-index: 1;
			}

			img {
				width: 100%;
			}
		}
	}
}

.bg-forest-green {
	background-color: #11312b;
	&-1 {
		background-color: #11312b;
	}
	&-2 {
		background-color: #1a3f38;
	}
}

.client-dashboard {
	width: 100%;
	min-height: 100%;
	background: #11312b;
	position: relative;
	overflow-x: hidden;

	canvas {
		z-index: 2000 !important;
	}

	.trophy-case {
		margin-top: 110px;
		position: relative;

		* {
			font-family: Lora, serif !important;
			color: #ede6cc;
		}

		& > svg {
			position: absolute;
			left: 0;
		}

		.trophy-content {
			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 32px;
				margin-bottom: 18px;
			}

			p {
				font-size: 18px;
				font-weight: 500;
				line-height: 32px;
				margin-bottom: 62px;
			}

			.trophy-choices {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				.badge-container {
					margin: 0 2px 20px 19px;
				}

				.badge-item {
					position: relative;
					width: 350px;
					max-width: 350px;
					min-width: 350px;
					height: 150px;
					min-height: 150px;
					max-height: 150px;
					background: #1d3b35;
					border-radius: 15px;
					border: 3px solid #12312b;
					margin-left: -9px;
					margin-right: 8px;
					cursor: pointer;

					.download {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #00000088;
						border-radius: 10px;
						opacity: 0;
						visibility: hidden;

						svg {
							stroke: white;
						}
					}

					&:hover {
						.download {
							opacity: 1;
							visibility: visible;
							transition: all ease 0.5s;
						}
					}

					&.badge1 {
						display: flex;
						flex-flow: row;

						.brand {
							width: 35%;
							padding: 30px;
							align-items: center;
							justify-content: center;
							display: flex;
							margin-right: 5px;
							fill: white;
						}

						.trees-planted {
							width: 65%;
							background-color: #1a3530;
							border-radius: 15px;
							text-align: right;
							display: flex;
							flex-flow: column;
							justify-content: center;
							padding: 30px;
							height: 100%;

							* {
								font-family: Gilroy, Arial, sans-serif !important;
								letter-spacing: 0.1px;
								font-size: 17px;
							}

							p {
								line-height: 18px;
								margin-bottom: 0;
								font-weight: 700;
							}

							label:first-of-type {
								margin-bottom: 15px;
							}
						}
					}

					&.badge2 {
						padding: 15px 30px 0 30px;
						align-items: center;
						display: flex;
						flex-flow: column;

						.brand {
							position: absolute;
							right: 15px;
							bottom: 15px;
							fill: #ffffff;
						}

						* {
							font-family: Gilroy, Arial, sans-serif !important;
						}

						p {
							margin-bottom: 0;
						}

						.leaf {
							display: flex;
							height: 100%;

							svg {
								height: 100%;
							}

							.trees {
								text-align: center;
								display: flex;
								flex-flow: column;
								justify-content: center;

								p {
									font-size: 36px;
									letter-spacing: 1px;
									line-height: 36px;
								}

								label {
									font-size: 18px;
									line-height: 26px;
									letter-spacing: 1px;
								}
							}
						}
					}

					&.badge3 {
						background-color: #ede6cc;

						.logo {
							position: absolute;
							left: 0;
							bottom: 10px;
							width: 100%;
							text-align: center;

							svg {
								height: 25px;
								fill: #1d3b35;
							}
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}

						.business {
							padding: 20px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							height: 100%;
							width: 100%;

							.leaf {
								width: 25%;
							}

							.climate {
								width: 70%;

								h3 {
									color: #1d3b35;
									font-family: Gilroy, Arial, sans-serif !important;
									font-size: 20px;
									line-height: 32px;
									font-weight: 600;
								}
							}
						}
					}
				}

				.trophy-item {
					background: #1d423cbf;
					border-radius: 120.5px 120.5px 0 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0 37px 27px;

					svg {
						margin-top: 83px;
						margin-bottom: 28px;
						fill: #ede6cc;
						width: 64px;
					}

					span {
						background: #ede6cc12;
						border-radius: 8px;
						font-size: 18px;
						font-weight: 500;
						line-height: 32px;
						padding: 8px 56px;
						width: 166px;
						text-align: center;
					}

					&.trophy-active {
						background: #ede6cc;
						margin: 0 48px;

						svg {
							margin-top: 107px;
							fill: #12342e;
						}

						span {
							background: #00000012;
							color: #12342e;
						}
					}
				}

				@media screen and (max-width: 850px) {
					flex-direction: column;

					.trophy-item {
						&.trophy-active {
							margin: 48px 0;
						}
					}
				}
			}

			.reach-goal {
				background: #1a3f38;
				border-radius: 3px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 61px 12px 48px;
				margin-top: 70px;
				margin-bottom: 63px;
				width: 100%;

				h2 {
					margin-bottom: 15px;
					text-align: center;
				}

				p {
					margin-bottom: 40px;
					font-style: italic;
				}

				button,
				.btn-link {
					background-color: #ede6cc12;
					border-radius: 8px;
					border: none;
					color: #ede6cc;
					font-size: 18px;
					line-height: 32px;
					padding: 8px 20px;

					& + button {
						margin-left: 36px;
					}
				}

				@media screen and (max-width: 450px) {
					button {
						width: 100%;

						& + button {
							margin-left: 0;
							margin-top: 36px;
						}
					}
				}
			}
		}
	}

	.reach-goal {
		background: #1a3f38;
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 61px 12px 48px;
		margin-top: 70px;
		margin-bottom: 63px;
		width: 100%;

		h2 {
			margin-bottom: 15px;
			text-align: center;
		}

		p {
			margin-bottom: 40px;
			font-style: italic;
		}

		button,
		.btn-link {
			background-color: #ede6cc12;
			border-radius: 8px;
			text-decoration: none;
			border: none;
			color: #ede6cc;
			font-size: 18px;
			line-height: 32px;
			padding: 8px 20px;

			& + button {
				margin-left: 36px;
			}
		}

		@media screen and (max-width: 450px) {
			button {
				width: 100%;

				& + button {
					margin-left: 0;
					margin-top: 36px;
				}
			}
		}
	}

	.dashboard-header {
		position: absolute;
		width: 100%;
		padding: 24px 40px;
		display: flex;
		z-index: 9;

		* {
			font-family: 'Inter', sans-serif !important;
		}

		.navbar {
			width: 100%;

			.navbar-brand {
				svg {
					width: 131px;
					fill: #fff;
				}
			}

			.navbar-toggler {
				border-color: transparent;
			}

			.navbar-nav {
				.nav-link {
					color: #1b4039;
					font-size: 18px;
					font-weight: 600;
					line-height: 20px;
					padding: 12px 8px;
					position: relative;
					margin: 0 8px;

					&.public-dash {
						background: linear-gradient(
							112.83deg,
							rgba(255, 255, 255, 0.82) 0%,
							rgba(255, 255, 255, 0.8) 110.84%
						);
						border: 1.5px solid #ffffff;
						border-radius: 15px;
						padding: 12px 32px;
					}

					&.active {
						&::after {
							content: '';
							position: absolute;
							width: 11px;
							height: 11px;
							border-radius: 50%;
							background-color: #11312b;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			@media screen and (max-width: 991px) {
				background: linear-gradient(
					112.83deg,
					rgba(255, 255, 255, 0.82) 0%,
					rgba(255, 255, 255, 0.8) 110.84%
				);
				padding: 0.5rem;
				border-radius: 5px;

				.navbar-brand svg {
					fill: #1b4039;
				}

				.navbar-nav {
					border-top: 1px solid #fff;
					margin-top: 12px;

					.nav-link {
						&:last-of-type {
							background: transparent;
							border: none;
							border-radius: 0;
							padding: 12px 8px;
						}

						&.active {
							&::after {
								top: calc((100% - 11px) / 2);
								left: -2px;
								transform: translateX(-50%);
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			position: fixed;
		}

		@media screen and (max-width: 575px) {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	.dashboard-content {
		color: #ede6cc;

		.content-header {
			position: relative;
			margin-bottom: 150px;

			svg {
				position: absolute;
				top: 125px;
				z-index: 1;
			}

			h1 {
				font-size: 64px;
				font-weight: 700;
				line-height: 84px;
				margin-top: 240px;
				z-index: 2;
				font-family: Lora, serif !important;
			}

			h2 {
				font-size: 30px;
				line-height: 84px;
				margin-bottom: 0;
				font-family: Lora, serif !important;
			}

			p {
				font-size: 18px;
				font-weight: 600;
				line-height: 33px;
				max-width: 450px;
			}

			img {
				width: calc(50vw - 12px);
				height: 700px;
			}

			.more-help {
				display: flex;
				position: absolute;
				bottom: -50px;
				width: auto;
				cursor: pointer;

				span,
				a {
					width: 145px;
					height: 100px;
					color: #11312b;
					font-size: 15px;
					font-weight: 600;
					line-height: 100px;
					text-align: center;
					text-decoration: none;

					&:nth-child(2n) {
						background: #ded8c0;
					}

					&:nth-child(2n + 1) {
						background: #ede6cc;
					}
				}
			}

			@media screen and (max-width: 991px) {
				h2 {
					line-height: 42px;
					margin-bottom: 18px;
				}
				p {
					max-width: 100%;
				}

				& > div:nth-child(2) {
					display: none;
				}

				.more-help {
					position: relative;
					flex-wrap: wrap;

					span {
						width: auto;
						padding: 0 12px;
					}
				}
			}
		}

		.dashboard-button {
			border: 1.5px solid #ede6cc;
			border-radius: 3px;
			color: #ede6cc;
			font-size: 18px;
			font-weight: 500;
			line-height: 32px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 14px 20px;
		}

		.trees-per-order {
			@media screen and (min-width: 992px) {
				& > div:last-of-type {
					padding: 0 56px;
				}
			}

			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 32px;
				margin-top: 39px;
				margin-bottom: 25px;
				font-family: Lora, serif !important;
			}

			p {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				margin-bottom: 0;
				max-width: 450px;

				@media screen and (max-width: 991.8px) {
					margin-top: 25px;
				}
			}

			.order-container {
				border: none;
				border-radius: 12px;
				display: flex;
				flex-direction: column;

				.order-header {
					background: #1a3b36;
					border-radius: 12px 12px 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 18px 0;

					span {
						font-size: 20px;
						font-weight: 500;
						line-height: 32px;
					}

					.order-info {
						background: #ede6cc;
						border-radius: 50%;
						width: 18px;
						height: 18px;
						margin-left: 10px;
						position: relative;

						&:before {
							content: 'i';
							color: #1a3b36;
							font-size: 14px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}

				.order-content {
					background: #ede6cc;
					border-radius: 0 0 12px 12px;
					padding: 30px;
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					overflow: hidden;

					label {
						color: #12342e;
						font-size: 21px;
						font-weight: 700;
						line-height: 32px;
						margin-bottom: 45px;
						font-family: Lora, serif !important;
					}

					.order-counts {
						display: flex;
						align-items: center;
						z-index: 2;

						span {
							color: #12342e;
							font-size: 30px;
							font-weight: 700;
							line-height: 32px;
							margin: 0 24px;
						}

						button {
							width: 54px;
							height: 54px;
							background: #1a3b36;
							border: 3px solid #ffffff94;
							border-radius: 50%;
							color: #ede6cc;
							font-size: 30px;
							font-weight: 700;
							line-height: 32px;
						}
					}

					svg {
						position: absolute;
						width: 170px;
						z-index: 1;

						path {
							stroke: #e1d9bd;
							stroke-opacity: 1;
						}

						&:first-of-type {
							bottom: -30px;
							left: 0;
							transform: rotate(-90deg);
						}

						&:last-of-type {
							transform: scale(2);
							right: 80px;
							bottom: -140px;
						}
					}
				}
			}
		}

		.brand-assets {
			margin-top: 150px;
			position: relative;

			@media screen and (min-width: 992px) {
				& > div:last-of-type {
					padding: 0 56px;
				}
			}

			.asset-item {
				margin-bottom: 12px;

				a {
					text-decoration: none;
				}

				& > a > div {
					background: #1a3f38;
					border-radius: 3px;
					width: 100%;
					height: 150px;

					.rotate-45 {
						transform: rotate(-45deg) scale(0.9);
					}

					span {
						color: #ede6cc;
						font-size: 18px;
						font-weight: 600;
						line-height: 18px;
						margin-top: 16px;
					}

					img {
						max-width: 70px;
						max-height: 70px;
					}
				}
			}

			h1 {
				font-size: 48px;
				font-weight: 700;
				line-height: 53px;
				margin-bottom: 35px;
				font-family: Lora, serif !important;

				@media screen and (max-width: 991px) {
					margin-top: 24px;
				}
			}

			p {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				max-width: 450px;
				margin-bottom: 35px;

				@media screen and (max-width: 991.8px) {
					margin-top: 25px;
				}
			}

			& > svg {
				position: absolute;
				transform: rotate(-110deg);
				right: -130px;
				top: 0;
				width: auto;
			}
		}

		.tree-content {
			margin-top: 100px;

			img {
				width: 100%;
				margin-bottom: 40px;
			}
		}

		.ecodrive-badge {
			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 32px;
				text-align: center;
				margin-bottom: 50px;
				font-family: Lora, serif !important;
			}

			h3 {
				font-size: 29px;
				font-weight: 700;
				line-height: 32px;
				margin-bottom: 25px;
			}

			p {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				margin-bottom: 35px;

				@media screen and (min-width: 992px) {
					max-width: 350px;
				}
			}

			.dashboard-button {
				@media screen and (max-width: 992px) {
					margin-bottom: 36px;
				}
			}

			.badge-item {
				background: #1d423cbf;
				border-radius: 120px 120px 103px 103px;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 206px;
				margin: 0 10px;

				.item-mark {
					width: 63px;
					height: 63px;
					border-radius: 50%;
					background: #294740;
					position: relative;
					margin: 25px 0 20px;

					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.item-reward {
					margin: 8px 0 40px;
					width: 141px;
					background: #ede6cc12;
					border-radius: 8px;
					color: #ede6cc;
					font-size: 18px;
					font-weight: 500;
					line-height: 32px;
					text-align: center;
					font-family: Lora, serif !important;
				}

				&.item-popular {
					background: #ede6cc;

					.item-mark {
						background: #dcd6be;
						margin-top: 40px;
					}

					.item-reward {
						background: #00000012;
						color: #12342e;
						font-size: 21px;
					}
				}
			}
		}

		.insert-cards {
			margin-top: 150px;
			align-items: center;

			@media screen and (min-width: 992px) {
				& > div:last-of-type {
					padding-left: 60px;
				}
			}

			@media screen and (max-width: 991px) {
				h2 {
					margin-top: 40px;
				}
			}

			img {
				width: 100%;
				border-radius: 10px;
			}

			h2 {
				font-size: 29px;
				font-weight: 700;
				line-height: 32px;
				margin-bottom: 40px;
			}

			p {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 35px;
			}
		}

		.marketing-strategy {
			margin-top: 100px;
			padding: 0 12px;

			& > div {
				background: #1a3f38;

				&:first-of-type {
					padding: 75px 23px 70px 63px;

					@media screen and (max-width: 991px) {
						padding-left: 23px;
					}
				}

				&:last-of-type {
					padding: 54px 23px;
				}
			}

			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 44px;
				margin-bottom: 30px;
				font-family: Lora, serif !important;
			}

			p {
				font-size: 18px;
				font-weight: 500;
				line-height: 24px;
				margin-bottom: 50px;
			}

			.nav-tabs {
				margin-bottom: 30px;
				border-color: transparent;

				.nav-link {
					width: auto;
					color: #ede6cc;

					&.active {
						border-color: #ede6cc;
					}
				}
			}

			button,
			a.dashboard-button {
				margin-right: 20px;
				width: 260px;
			}

			button:last-of-type {
				background: #11312b;
				margin-right: 0;
			}

			.form-check {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				margin-right: 12px;

				input {
					background-color: #afbab821;
					border: 1px solid #ffffff69;
					border-radius: 7px;
					width: 29px;
					height: 29px;
				}

				label {
					margin-left: 10px;
					font-size: 18px;
					line-height: 32px;
				}
			}

			svg {
				position: absolute;
				transform: rotate(-110deg);
				top: -35px;
				right: 0;
			}

			img {
				position: absolute;
				bottom: -70px;
				right: -60px;
			}

			@media screen and (max-width: 550px) {
				img {
					display: none;
				}
			}
		}

		.more-trees {
			margin-top: 100px;

			.trees-content {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				background: #ede6cc;
				border-radius: 33px;
				padding: 60px 95px;

				.btn-link {
					background: #11312b;
					border: none;
					color: #ede6cc;
					padding: 12px 32px;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					font-family: 'Inter', sans-serif !important;
					text-decoration: none;
				}

				.content-detail {
					display: flex;
					align-items: center;
					color: #11312b;

					svg {
						fill: #5e8e3e;
						width: 108px;
						height: 108px;
						min-width: 40px;
						min-height: 40px;
					}

					div {
						max-width: 600px;
						margin-left: 32px;

						h2 {
							font-size: 36px;
							font-weight: 700;
							line-height: 54px;
							margin-bottom: 5px;
							font-family: Lora, serif !important;
						}

						.holiday-calendar {
							font-size: 26px;
						}

						p {
							font-size: 16px;
							font-weight: 500;
							line-height: 24px;
							margin-bottom: 0;
							font-family: 'Inter', sans-serif !important;
						}
					}
				}

				button {
					background: #11312b;
					border: none;
					border-radius: 6px;
					color: #ede6cc;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					padding: 12px 32px;
					font-family: 'Inter', sans-serif !important;
				}

				@media screen and (max-width: 991px) {
					flex-direction: column;

					button {
						margin-top: 24px;
					}
				}
			}
		}

		.need-help {
			margin-top: 100px;

			& > div {
				margin-bottom: 90px;
			}

			.help-header {
				position: relative;

				img {
					width: 100%;
				}

				.overlay {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient(
						181.19deg,
						#11312b 15.63%,
						rgba(0, 0, 0, 0) 156.94%
					);
				}
			}

			.help-content {
				background: #ede6cc;
				border-radius: 0 0 33px 33px;
				color: #11312b;
				padding: 60px 90px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					font-size: 36px;
					font-weight: 700;
					line-height: 54px;
					margin-bottom: 12px;
					font-family: Lora, serif !important;
				}

				p {
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					margin-bottom: 0;
					font-family: 'Inter', sans-serif !important;
				}

				svg {
					margin: 0 10px;
				}

				.btn-link {
					background: #11312b;
					border: none;
					color: #ede6cc;
					padding: 12px 32px;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					height: 48px;
					width: 203px;
					font-family: 'Inter', sans-serif !important;
					text-decoration: none;
				}

				@media screen and (max-width: 1199px) {
					flex-direction: column;

					svg {
						display: none;
					}

					button {
						margin-top: 24px;
					}
				}
			}
		}
	}

	.dashboard-footer {
		background: #1a3f38;
		padding-top: 60px;
		padding-bottom: 60px;

		.social-wrapper {
			display: flex;
			margin: 28px 0;

			.social-item {
				width: 32px;
				height: 32px;
				margin-right: 16px;
				position: relative;
				background: #ddd7bf;
				border-radius: 50%;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		p {
			color: #ede6cc;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			font-family: Lora, serif !important;
		}

		.btn-link {
			text-decoration: none;
			padding: 0;
			margin-bottom: 11px;
			color: #ede6cc;
			font-family: Lora, serif !important;
		}

		label {
			color: #ede6cc;
			font-size: 20px;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 24px;
			font-family: 'Inter', sans-serif !important;
		}

		.input-wrapper {
			position: relative;

			input {
				background: #bdb8a633;
				color: #ede6cc;
				padding: 9px 48px 9px 12px;
				font-size: 14px;
				line-height: 24px;
				font-family: 'Inter', sans-serif !important;
			}

			.btn-link {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.supply-chain {
		margin-top: 0 !important;
		margin-bottom: 90px;
	}

	.copyright {
		padding-top: 15px;
		padding-bottom: 15px;

		span {
			font-family: Lora, serif !important;
		}

		span,
		.btn-link {
			color: #ede6cc;
			font-size: 14px;
			line-height: 32px;
			text-decoration: none;
		}

		.btn-link {
			line-height: 30px;
			padding-top: 0;
			padding-bottom: 0;
		}

		a {
			margin-right: 40px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.marketing-section {
		margin-top: 100px;
		text-align: center;

		* {
			font-family: 'Inter', sans-serif !important;
		}

		h2 {
			margin-bottom: 0;
			padding: 25px 0;
		}

		.marketing-container {
			.marketing-wrapper {
				background-color: #ede6ce;
				padding: 80px 20px 25px;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					opacity: 0.3;
					z-index: 0;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: url(../../../../assets/images/dashboard/forest-bg.png);
					background-size: auto 300px;
					background-repeat: repeat-x;
					background-position: bottom;
				}
			}

			h4 {
				color: #0f312b;
			}

			p {
				color: #000000;
			}

			.bullet-item {
				display: flex;
				flex-flow: row;
				align-items: center;
				flex-wrap: nowrap;
				text-align: left;
				margin-bottom: 20px;

				span {
					height: 56px;
					background-color: #0f312b;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					flex: 0 0 56px;
					font-size: 26px;
				}

				p {
					margin-bottom: 0;
					margin-left: 20px;
					color: #0f312b;
					font-weight: 500;
				}
			}

			.bullet-group {
				display: flex;
				flex-flow: column;
				justify-content: space-between;

				h5 {
					text-align: left;
					color: #0f312b;
					font-weight: 600;
				}
			}

			.bullet-container {
				max-width: 1200px;
				margin: 0 auto;
				z-index: 1;
			}

			.bullet-wrapper {
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				z-index: 1;
			}

			.marketing-img {
				padding: 0 20px;

				img {
					width: 100%;
					height: auto;
				}
			}

			.btn-container {
				display: flex;
				margin: 0 -15px 40px;

				@media screen and (max-width: 991px) {
					justify-content: center !important;
				}

				@media screen and (max-width: 575px) {
					.btn-link {
						width: calc(100% - 30px) !important;
					}
				}

				.btn-link {
					width: calc(60% - 30px);
					background: #ede6ce;
					color: #11312b;
					padding: 12px 32px;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					font-family: 'Inter', sans-serif !important;
					text-decoration: none;
					margin: 0 15px;
					border-radius: 0;
					border: 1px solid #11312b;
				}

				.btn-join {
					color: #ede6ce;
					background: #11312b;
				}
			}
		}
	}
}
