.filter-buttons .btn {
	transition: background-color 0.3s ease-in-out;
	border-radius: 20px;
	color: rgba(0, 0, 0, 0.625);
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.75px;
	padding: 4px 15px 1px 15px;
	text-transform: uppercase;

	&:hover {
		background-color: #d4d4d4;
	}
}

.selected-filter {
	background-color: #77cfc6;
	color: #ffffff;
}

.not-selected-filter {
	border-color: #77cfc6;
}
