.custom-modal-container {
	align-items: center;
	display: flex;
	min-height: 100vh;
	left: 0;
	justify-content: space-around;
	position: fixed;
	top: 0;
	min-width: 100vw;
	z-index: 10;
}

.custom-modal-backdrop {
	background-color: #000;
	height: 100vh;
	left: 0;
	opacity: 0.3;
	position: absolute;
	top: 0;
	width: 100vw;
	z-index: 1;
}

.custom-modal {
	display: flex;
	background: #ffffff;
	// TODO: remove height
	height: 500px;

	max-height: 100vh;

	max-width: 758px;
	position: relative;
	width: 100%;
	z-index: 2;
}

.custom-modal--small {
	max-width: 550px;
}

.custom-modal--medium {
	max-width: 758px;
}

.custom-modal--large {
	height: auto;
	max-height: 90vh;
	max-width: 90vw;
}

.custom-modal__close-container {
	color: #77cfc69f;
	cursor: pointer;
	height: 35px;
	padding: 5px;
	position: absolute;
	right: 17px;
	top: 15px;
	transition: color 0.2s;
	width: 35px;
	&:hover {
		color: #4f888360;
	}
}

.custom-modal__close {
	align-items: center;
	display: flex;
	height: 100%;
	margin: auto;
	justify-content: space-around;
	width: 100%;
	svg {
		height: auto;
		width: 100%;
	}
}

.custom-modal__left-container {
	background: #e0f8f483;
	display: flex;
	flex-direction: column;
	max-width: 253px;
	overflow-y: auto;
	padding: 25px;
	width: 100%;
}

.custom-modal__right-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 45px 25px 25px 25px;
	width: 100%;
	border-left: 1px solid rgba(211, 211, 211, 0.5);

	p:has(img) {
		text-align: center;
	}

	img {
		max-width: 100%;
		margin: 0 auto;
	}
}

.custom-modal__right-header-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 3px;
}
.custom-modal__right-header {
	display: flex;
	flex-direction: column;
	font-size: 32px;
	font-weight: 600;
	width: 100%;
	padding-top: 2px;
}

.custom-modal__right-subheader {
	color: #000;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	width: 100%;
	padding-top: 5px;
	font-style: italic;
	letter-spacing: 0.75px;
}
