.what-we-plant {
	margin-top: 55px;

	.plant-content {
		width: 100%;
	}

	h2 {
		margin-bottom: 31px;
		font-family: Lora, serif !important;
	}

	p {
		margin-bottom: 9px;
		font-family: Lora, serif !important;
	}

	.btn-link {
		color: #ede6cc;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		text-decoration: none;
		border-bottom: 1px solid #ede6cc;
		width: min-content;
		white-space: nowrap;
		padding: 0;
		border-radius: 0;
		text-transform: uppercase;
		margin-top: 10px;
	}

	.img-wrapper {
		background: linear-gradient(
			166.1deg,
			rgba(54, 104, 98, 0) 2.32%,
			#224944 133.95%
		);
		border-radius: 50%;
		padding: 28px;

		img {
			max-width: 511px;
			width: 100%;
			border-radius: 50%;
		}
	}

	& + .what-we-plant {
		@media screen and (max-width: 768px) {
			& > div:last-of-type {
				order: -1;
			}
		}
	}
}
