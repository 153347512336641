.billing-info {
  width: calc(100% - 24px);
  max-width: 700px;

  & > label {
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }

  form {
    margin-top: 60px;
    text-align: left;

    .action-buttons {
      margin-top: 28px;
    }
  }
}