.dashboard-container {
	width: 100%;
	min-height: 100%;
	position: relative;
	background: #f8f9fa;

	.banner {
		background: url('../../../assets/images/admin/banner.png') center center;
		background-size: cover;
		border-radius: 0 0 24px 24px;
		width: 100%;
		height: 545px;
		top: 0;
	}

	.public-url {
		color: #2d3748;
		cursor: pointer;
	}

	.header {
		margin-top: -478px;
	}

	.dashboard-info {
		margin-top: -357px;
		padding: 0 0 32px;
		width: calc(100% - 24px);

		.status-card {
			background: #fff;
			border-radius: 16px;
			padding: 19px 17px;
			display: flex;
			align-items: center;
			height: 100%;
			overflow: hidden;

			&.highlight {
				background: #ffaf67;

				.card-content {
					p,
					span {
						color: #fff;
					}
				}
			}

			.card-content {
				flex-grow: 1;

				p {
					color: #a8a8a8;
					font-size: 11px;
					line-height: 17px;
					margin-bottom: 3px;
				}

				span {
					color: #2d3748;
					font-size: 20px;
					line-height: 28px;
				}
			}

			img {
				height: 44px;
			}

			&.left-order {
				.card-content {
					padding-right: 9px;
				}

				img {
					padding-left: 9px;
				}
			}

			&.right-order {
				.card-content {
					padding-left: 9px;
				}

				img {
					padding-right: 9px;
				}
			}
		}

		.dashboard-filter {
			background: #ffffff4d;
			backdrop-filter: blur(27px);
			box-shadow: 0 4px 4px 0 #00000040;
			border-radius: 15px;
			color: #fff;
			padding: 23px;

			.filter-title {
				font-size: 14px;
				font-weight: bold;
				line-height: 20px;
			}

			@media screen and (max-width: 767px) {
				color: #2d3748;
			}
		}

		.transactions-filter {
			background: rgb(23, 169, 143);
			box-shadow: 0 4px 4px 0 #00000040;
			border-radius: 15px;
			color: #fff;
			padding: 23px;

			.transactions-title {
				font-size: 14px;
				font-weight: bold;
				line-height: 20px;
			}
			.order-filters {
				.order-label {
					color: #000;
				}
			}
		}

		.filter-select {
			background: #ffffff36;
			border: 1px solid #ffffff47;
			border-radius: 11px;
			color: #fff;
			font-size: 14px;
			line-height: 1.4;
			padding: 15px 20px;
			display: block;
			width: 100%;
			appearance: none;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

			&:focus {
				box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
				outline: 0;
			}
		}

		.filter-option {
			color: #000;
		}

		.client-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 12px 24px;

			.go-back {
				cursor: pointer;

				svg {
					fill: #000;
					margin-right: 36px;
				}
			}

			.client-logo {
				flex-grow: 1;

				img {
					width: auto;
					max-width: 200px;
					max-height: 32px;
				}
			}

			.button {
				margin-left: 36px;
				padding: 12px 15px;
			}
		}

		.dashboard-card {
			background: #fff;
			border-radius: 15px;
			padding: 32px 24px;

			.card-title {
				color: #2d3748;
				font-size: 18px;
				font-weight: 700;
				line-height: 25px;
				margin-bottom: 20px;
			}
		}

		.clients-card {
			.clients-container {
				margin-bottom: 20px;

				.client-row {
					display: flex;
					align-items: center;
					padding: 12px 36px;
					background: #f8f9fa;
					border-radius: 12px;
					margin-bottom: 15px;

					.row-name {
						line-break: anywhere;
						width: 35%;
					}

					.row-status {
						color: #1d6d64;
						width: 20%;
						text-align: center;
						text-transform: capitalize;
					}

					.row-value {
						width: 25%;
						text-align: center;
					}

					.row-time {
						width: 20%;
						text-align: center;
					}

					span {
						color: #4f4f4f;
						font-size: 14px;
						font-weight: 700;
						line-height: 1.4;
					}

					&.row-header {
						background: #fff;
						border: 1px solid #e1e1e1;
						cursor: default;
						padding: 14px 36px;

						span {
							color: #718096;
						}
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			margin-top: 40px;
		}
	}

	&.dashboard-client {
		.banner {
			height: 295px;
		}

		.header {
			margin-top: -228px;
		}

		.dashboard-info {
			margin-top: -107px;

			@media screen and (max-width: 991px) {
				margin-top: 40px;
			}
		}
	}
	.client-logo {
		p {
			color: #2d3748;
			font-size: 18px;
			font-weight: 700;
			line-height: 25px;
			margin: 0;
		}
	}
}
