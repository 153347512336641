.milestone-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  label {
    color: #2D3748;
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    margin-bottom: 16px;
  }

  & > p {
    color: #2D3748;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }

  .milestones {
    display: flex;
    margin-top: 30px;

    button {
      background: #F8F9FA5E;
      border: 1px solid #CECECE;
      border-radius: 16px;
      width: 150px;
      height: 150px;
      margin: 6px;

      .image-container {
        background: #F5F5F5;
        border: 1px solid #7B7B7B47;
        border-radius: 50%;
        padding: 15px;
        margin: 0 auto;
        width: 70px;
        height: 70px;

        svg {
          fill: #393939;
        }
      }

      p {
        color: #2D3748;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        margin: 14px 0 0;
      }

      &:hover, &.active {
        background: #4FD1C514;
        border: 1px solid #4FD1C5;

        .image-container {
          background: #4FD1C51A;
          border: 1px solid #4FD1C547;

          svg {
            fill: #4FD1C5;
          }
        }

        p {
          color: #4FD1C5;
        }
      }
    }

    @media screen and (max-width: 659px) {
      flex-wrap: wrap;
      justify-content: center;

      button {
        width: calc(50% - 12px);
      }
    }
  }
}