@use './containers';
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap');

@font-face {
  font-family: 'Gilroy';
  src: url(../fonts/Gilroy/Gilroy-Medium.ttf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../fonts/Gilroy/Gilroy-MediumItalic.ttf) format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../fonts/Gilroy/Gilroy-SemiBold.ttf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../fonts/Gilroy/Gilroy-Bold.ttf) format("opentype");
  font-weight: 700;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

* {
  font-family: 'Gilroy', Arial, sans-serif !important;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
  height: 100%;
}

.form-label {
  color: #2D3748;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  padding-left: 3.5px;
}

.form-control {
  background: #F8F8F8;
  border: none;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.4;
  padding: 15px 20px;
}

.button {
  border: none;
  border-radius: 12px;
  color: #FFF;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  padding: 15px;
  width: 200px;


  &.disabled, &:disabled {
    opacity: 0.65;
  }
}

.btn:hover {
  opacity: .75!important;
}

.button-primary {
  background-color: #151928;

  &:hover, &:disabled {
    background-color: #151928;
    opacity: 0.9;
  }
}

.button-secondary {
  background-color: #FFAF67;

  &:hover, &:disabled {
    background-color: #FFAF67;
    opacity: 0.9;
  }
}

.full-width {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.fa {
  font-family: 'FontAwesome' !important;
}
