.client-container {
  .banner {
    background: url("../../../assets/images/admin/banner.png") center center;
    background-size: cover;
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 138px;
    top: 0;
  }

  .public-url {
    color: #2D3748;
    cursor: pointer;
  }

  .header {
    margin-top: -36.5px;
  }

  .client-info {
    text-align: center;
    padding: 100px 0 48px;

    .client-header {
      display: flex;
      position: relative;
      justify-content: center;
      margin-bottom: 50px;

      .go-back {
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          fill: #000;
        }
      }

      img {
        width: auto;
        max-width: 500px;
        max-height: 80px;
      }
    }

    .project-select {
      margin-bottom: 16px;
    }

    .client-content {
      width: calc(100% - 24px);
      max-width: 850px;
      margin: 0 auto;
      color: #2D3748;

      h2 {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.4;
      }

      h1, .h1 a {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.4;
        color: #2D3748;
      }

      .input-group {
        .react-datepicker-wrapper {
          flex-grow: 1;
          width: auto;

          input {
            width: 100%;
            outline: none;

            &:focus {
              box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
            }
          }
        }

        .input-group-text {
          background: #C4C4C4;
          border: none;
          border-radius: 15px 0 0 15px;
          justify-content: center;
          width: 44px;
        }

        input {
          border: none;
          border-radius: 0 15px 15px 0;
          background: #F8F8F8;
          padding: 15px 7px;
          color: #A0AEC0;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
        }
      }

      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .button {
          &:first-of-type {
            margin-right: 12px;
          }
        }
      }

      .form-label {
        width: 100%;
        text-align: left;
      }

      .file-input {
        width: 100%;
      }
    }
  }

  .form-check-input:checked {
    background-color: #000;
    border-color: #000;
  }

  .form-check-input:focus {
    border-color: #454a51;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28150, 150, 150, 0.25%29%27/%3e%3c/svg%3e");
  }
}
