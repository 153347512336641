.trees-container {
  margin: 50px auto;
  width: 100%;
  max-width: 790px;

  .milestone-choice {
    align-items: flex-start;
    margin-bottom: 50px;

    label {
      margin-bottom: 0;
      color: #000000;
    }

    p {
      font-size: 12px;
      line-height: 16.8px;
      color: #A0AEC0;
      margin-bottom: 44px;
      font-weight: 700;
      text-align: left;
    }

    .milestones {
      width: 100%;
      margin-top: 0;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        justify-content: center;
        flex-wrap: wrap;
      }

      button {
        margin: 0 20px 0 0;
        width: 180px;
        height: 180px;

        @media screen and (max-width: 1200px) {
          width: 150px;
          height: 150px;
          margin: 0 10px;
        }

        @media screen and (max-width: 991px) {
          width: calc(50% - 12px);
          margin: 6px;
        }

        @media screen and (min-width: 992px) {
          &:last-of-type {
            margin-right: 0;
          }
        }

        &.active, &:hover {
          border-color: #000;
          background-color: #F7F7FA;

          .image-container {
            background: transparent;
            border-color: transparent;

            svg {
              fill: #000;
            }
          }

          p {
            color: #000;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }

  .trees-order {
    border: none;
    background-color: transparent;

    label {
      display: none;
    }

    .trees-count {
      span {
        font-size: 80px;
        color: #000000;
        line-height: 112px;
        margin: 0 100px;
      }

      button {
        width: 63px;
        height: 63px;
        background-color: #4FD1C5;
        color: #FFFFFF;
        box-shadow: 11px 13px 40px rgba(0, 0, 0, 0.15);
        border: none;
      }
    }

    @media screen and (max-width: 575px) {
      padding: 24px 0;

      .trees-count {
        span {
          margin: 0 40px;
        }

        button {
          width: 48px;
          height: 48px;
          background-color: #4FD1C5;
          color: #FFFFFF;
          box-shadow: 11px 13px 40px rgba(0, 0, 0, 0.15);
          border: none;
        }
      }
    }
  }
}