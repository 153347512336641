.bubble-selection {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.bubble-option {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid grey;
	cursor: pointer;
	transition: all 0.3s ease;
}

.bubble-option:hover,
.bubble-option.selected {
	background-color: #77cfc6;
	color: white;
	border-color: #77cfc6;
}
