.payment-info {
  width: calc(100% - 24px);
  max-width: 460px;

  & > label {
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    margin-bottom: 17px;
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }

  & > img {
    margin-top: 55px;
    width: 100%;
    max-width: 350px;
  }

  & > .rccs {
    margin-top: 55px;
  }

  form {
    margin-top: 36px;
    text-align: left;

    .action-buttons {
      margin-top: 20px;
    }
  }

  .promo-button {
    color: #fff;
    font-size: 14px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #151928;

    &:hover {
      background-color: #151928;
      opacity: 0.9;
    } &:disabled {
      background-color: #151928;
      opacity: .25;
    }
  }
  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-right: 12px;

    input {
      border-radius: 7px;
      min-width: 20px;
      height: 20px;
    }

    label {
      margin-left: 10px;
      font-size: 14px;
      line-height: 19.6px;

      .btn-link {
        box-shadow: none;
        color: #212529;
        padding: 0;
        font-size: 14px;
        line-height: 19.6px;
      }
    }
  }
}
