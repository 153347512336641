.custom-modal__left-footer {
	align-items: center;
	display: flex;
	justify-content: space-around;
	margin-top: auto;
	padding-top: 13px;
	width: 100%;
}

.custom-modal__left-button {
	align-items: center;
	color: rgba(181, 181, 181, 0.8);

	cursor: no-drop;
	display: flex;
	font-size: 12px;
	font-weight: 500;
	justify-content: space-around;
	padding-bottom: 10px;
	padding-top: 10px;
	transition: color 0.2s;
	width: 100%;
	&.active {
		cursor: pointer;
		&:hover {
			color: #77cfc6;
		}
	}
}

// TODO: remove
.custom-modal__default-button {
	svg {
		height: auto;
		margin-bottom: 1px;
		margin-right: 7px;
		width: 15px;
	}
}
