.plant-extra {
	.modal-content {
		background-color: #1d3b35;
		border-radius: 20px;
		border: none;

		.modal-header {
			flex-flow: column;
			padding: 20px 5px 30px;
			text-align: center;
			border: none;
			position: relative;

			.z-1 {
				z-index: 1;
			}

			.btn-primary:focus {
				background-color: #ffaf67;
			}

			.spinner-border {
				width: 16px;
				height: 16px;
			}

			.action-buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 1;
			}

			.custom-trees {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 0 40px;

				.input-group-text {
					border-top-left-radius: 15px;
					border-bottom-left-radius: 15px;
				}
			}

			.b-layer {
				position: absolute;
				bottom: 40px;
			}

			.form-check {
				text-align: left;

				.form-check-label {
					font-size: 16px;
					line-height: 19.6px;
					color: #fff;
					letter-spacing: 0.5px;

					.btn-link {
						box-shadow: none;
						padding: 0;
						font-size: 14px;
						line-height: 19.6px;
						color: #fff;
					}
				}
			}

			p {
				font-size: 22px;
				line-height: 28px;
				font-weight: 400;
				color: #fff;
				margin-bottom: 30px;
				font-family: 'Gilroy', Arial, sans-serif !important;
				padding: 0 40px;
			}

			.btn-close {
				position: absolute;
				top: 30px;
				right: 30px;
				color: #fff;
				z-index: 2;
				background: transparent
					url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
					center/1em auto no-repeat;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.public-dashboard {
	position: relative;
	background: #12342e;
	overflow-x: hidden;

	.dashboard-banner {
		position: absolute;
		width: 100%;

		img {
			width: 100%;
			max-height: 799px;
		}

		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background: linear-gradient(360deg, #12342e 0%, rgba(0, 0, 0, 0) 67.83%);
		}
	}

	.dashboard-header {
		position: absolute;
		width: 100%;
		padding: 24px 40px;
		display: flex;
		z-index: 9;

		* {
			font-family: 'Inter', sans-serif !important;
		}

		.navbar {
			width: 100%;

			.navbar-brand {
				svg {
					width: 131px;
					fill: #fff;
				}
			}

			.navbar-toggler {
				border-color: transparent;
			}

			.navbar-nav {
				.nav-link {
					color: #1b4039;
					font-size: 18px;
					font-weight: 600;
					line-height: 20px;
					padding: 12px 8px;
					margin: 0 8px;
					position: relative;

					&.public-dash {
						background: linear-gradient(
							112.83deg,
							rgba(255, 255, 255, 0.82) 0%,
							rgba(255, 255, 255, 0.8) 110.84%
						);
						border: 1.5px solid #ffffff;
						border-radius: 15px;
						padding: 12px 32px;
					}

					&.active {
						&::after {
							content: '';
							position: absolute;
							width: 11px;
							height: 11px;
							border-radius: 50%;
							background-color: #11312b;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
				.back-to-site {
					color: white;
				}
				.join-ecodrive {
					color: white;
				}
			}

			@media screen and (max-width: 991px) {
				background: linear-gradient(
					112.83deg,
					rgba(255, 255, 255, 0.82) 0%,
					rgba(255, 255, 255, 0.8) 110.84%
				);
				padding: 0.5rem;
				border-radius: 5px;

				.navbar-brand svg {
					fill: #1b4039;
				}

				.navbar-nav {
					border-top: 1px solid #fff;
					margin-top: 12px;

					.nav-link {
						&:last-of-type {
							background: transparent;
							border: none;
							border-radius: 0;
							padding: 12px 8px;
						}

						&.active {
							&::after {
								top: calc((100% - 11px) / 2);
								left: -2px;
								transform: translateX(-50%);
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			position: fixed;
		}

		@media screen and (max-width: 575px) {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	.dashboard-content {
		position: relative;
		z-index: 2;
		color: #ede6cc;

		h2 {
			font-size: 30px;
			line-height: 32px;
		}

		.dashboard-button {
			width: 300px;
			color: #ede6cc;
			font-size: 18px;
			font-weight: 500;
			line-height: 32px;
			padding: 14px;
			text-decoration: none;
			border: 1.5px solid #ede6cc;
		}

		.positive-impact {
			padding-top: 125px;

			svg {
				width: 262px;
				fill: #ede6cc;
				margin-bottom: 46px;
			}
			.logo {
				max-width: 450px;
				margin-bottom: 25px;
				max-height: 350px;
			}
			h1 {
				font-size: 72px;
				font-weight: 700;
				line-height: 84px;
				text-align: center;
				margin-bottom: 46px;
				font-family: Lora, serif !important;
			}

			.logo-text {
				font-size: 36px;
				line-height: 42px;
			}

			p {
				font-size: 29px;
				font-weight: 500;
				letter-spacing: 0.1em;
				line-height: 32px;
				text-align: center;
				margin-bottom: 0;
			}

			.impact-status {
				margin: 76px 18px 0;
				padding: 36px 74px;
				background: #224b45;
				border-radius: 60px 60px 0 0;
				width: 100%;
				display: flex;

				* {
					font-family: Lora, serif !important;
				}

				.status-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					flex: 1;

					.status-logo {
						width: 64px;
						height: 64px;
						background: #0f322c;
						border-radius: 50%;
						position: relative;
						margin-bottom: 16px;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

					p {
						font-size: 36px;
						font-weight: 700;
						line-height: 64px;
						color: #ede6cca3;
					}

					label {
						font-size: 18px;
						line-height: 32px;
						color: #fff;
						padding: 0 30px;
						text-align: center;

						small {
							display: block;
							line-height: 1;
						}
					}

					&.status-active {
						border-left: 1px solid #ede6cc;
						border-right: 1px solid #ede6cc;

						p {
							font-size: 48px;
							color: #fff;
						}

						label {
							font-size: 24px;
							color: #fff;
						}
					}
				}

				@media screen and (max-width: 1199px) {
					padding: 36px 32px;
				}
			}

			@media screen and (max-width: 991px) {
				padding-top: 170px;

				h1 {
					font-size: 54px;
					line-height: 64px;
					margin-bottom: 34px;
				}

				.logo-text {
					font-size: 24px;
					line-height: 30px;
				}

				p {
					font-size: 21px;
				}

				.impact-status {
					flex-direction: column;

					.status-item {
						padding: 24px 0;

						&.status-active {
							border-left: none;
							border-right: none;
							border-top: 1px solid #ede6cc;
							border-bottom: 1px solid #ede6cc;
						}

						&:first-of-type {
							padding-top: 0;
						}

						&:last-of-type {
							padding-bottom: 0;
						}
					}
				}
			}
		}

		.positive-orders {
			margin-top: 109px;
			margin-bottom: 83px;

			h2 {
				margin-bottom: 42px;
				font-family: Lora, serif !important;
			}

			.orders-container {
				border: 1px solid rgba(237, 230, 204, 0.36);
				border-radius: 9px;
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 0 68px;

				.order-filters {
					padding: 48px 0 60px;

					.form-label {
						color: #ede6cc8a;
					}

					.form-control {
						border: none;
						background: #ede6cc1a;
					}

					.form-switch {
						.form-check-label {
							font-size: 18px;
							font-weight: 600;
						}

						.form-check-input {
							background-color: #ede6cc;
							margin-top: 7px;
						}
					}

					.export-button {
						span {
							font-size: 18px;
						}
					}

					@media screen and (max-width: 767px) {
						color: #ede6cc !important;
					}
				}

				.orders-statistic {
					border-top: 1px solid #ede6cc70;
					padding: 35px 0;

					p {
						font-size: 20px;
						font-weight: 600;
						line-height: 32px;
					}

					.progress-container {
						margin-bottom: 10px;

						.progress-active {
							height: 26px;

							span {
								color: #1d423c;
								line-height: 26px;
								font-family: Lora, serif !important;
								font-size: 14px;
							}
						}

						.progress-inactive {
							height: 26px;
							background: #ede6cc21;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}

				@media screen and (max-width: 768px) {
					padding: 0 32px;
				}
			}
		}

		.tree-milestones {
			* {
				font-family: Lora, serif !important;
			}

			h2 {
				margin-bottom: 36px;

				span {
					color: #fff;
				}
			}

			.milestones-container {
				background: #ede6cc;
				border-radius: 0 0 33px 33px;
				color: #12342e;
				padding: 50px 75px;
				width: 100%;

				label {
					font-size: 24px;
					font-weight: 700;
					line-height: 32px;
					margin-bottom: 6px;
				}

				p {
					font-size: 18px;
					font-weight: 500;
					line-height: 32px;
					margin-bottom: 6px;
				}

				.milestone-steps {
					display: none;
				}

				.milestone-steps:first-of-type {
					display: block;
				}

				.progress {
					background-color: #d4cbd0;
					border-radius: 0;
					height: 37px;

					.progress-bar {
						background-color: #12342e;
					}
				}
			}
		}
	}

	.trophy-case {
		margin-top: 110px;
		position: relative;

		* {
			font-family: Lora, serif !important;
		}

		& > svg {
			position: absolute;
			left: 0;
		}

		.trophy-content {
			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 32px;
				margin-bottom: 18px;
			}

			p {
				font-size: 18px;
				font-weight: 500;
				line-height: 32px;
				margin-bottom: 62px;
			}

			.trophy-choices {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				.badge-container {
					margin: 0 2px 20px 19px;
				}

				.badge-item {
					position: relative;
					width: 350px;
					max-width: 350px;
					min-width: 350px;
					height: 150px;
					min-height: 150px;
					max-height: 150px;
					background: #1d3b35;
					border-radius: 15px;
					border: 3px solid #12312b;
					margin-left: -9px;
					margin-right: 8px;
					cursor: pointer;

					.download {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #00000088;
						border-radius: 10px;
						opacity: 0;
						visibility: hidden;

						svg {
							stroke: white;
						}
					}

					&:hover {
						.download {
							opacity: 1;
							visibility: visible;
							transition: all ease 0.5s;
						}
					}

					&.badge1 {
						display: flex;
						flex-flow: row;

						.brand {
							width: 35%;
							padding: 30px;
							align-items: center;
							justify-content: center;
							display: flex;
							margin-right: 5px;
							fill: white;
						}

						.trees-planted {
							width: 65%;
							background-color: #1a3530;
							border-radius: 15px;
							text-align: right;
							display: flex;
							flex-flow: column;
							justify-content: center;
							padding: 30px;

							* {
								font-family: Gilroy, Arial, sans-serif !important;
								letter-spacing: 0.1px;
								font-size: 17px;
							}

							p {
								line-height: 18px;
								margin-bottom: 0;
								font-weight: 700;
							}

							label:first-of-type {
								margin-bottom: 15px;
							}
						}
					}

					&.badge2 {
						padding: 15px 30px 0 30px;
						align-items: center;
						display: flex;
						flex-flow: column;

						.brand {
							position: absolute;
							right: 15px;
							bottom: 15px;
							fill: #ffffff;
						}

						* {
							font-family: Gilroy, Arial, sans-serif !important;
						}

						p {
							margin-bottom: 0;
						}

						.leaf {
							display: flex;
							height: 100%;

							svg {
								height: 100%;
							}

							.trees {
								text-align: center;
								display: flex;
								flex-flow: column;
								justify-content: center;

								p {
									font-size: 36px;
									letter-spacing: 1px;
									line-height: 36px;
								}

								label {
									font-size: 18px;
									line-height: 26px;
									letter-spacing: 1px;
								}
							}
						}
					}

					&.badge3 {
						background-color: #ede6cc;

						.logo {
							position: absolute;
							left: 0;
							bottom: 10px;
							width: 100%;
							text-align: center;

							svg {
								height: 25px;
								fill: #1d3b35;
							}
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}

						.business {
							padding: 20px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							height: 100%;

							.leaf {
								width: 25%;
							}

							.climate {
								width: 70%;

								h3 {
									color: #1d3b35;
									font-family: Gilroy, Arial, sans-serif !important;
									font-size: 20px;
									line-height: 32px;
									font-weight: 600;
								}
							}
						}
					}
				}

				.trophy-item {
					background: #1d423cbf;
					border-radius: 120.5px 120.5px 0 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0 37px 27px;

					svg {
						margin-top: 83px;
						margin-bottom: 28px;
						fill: #ede6cc;
						width: 64px;
					}

					span {
						background: #ede6cc12;
						border-radius: 8px;
						font-size: 18px;
						font-weight: 500;
						line-height: 32px;
						padding: 8px 56px;
						width: 166px;
						text-align: center;
					}

					&.trophy-active {
						background: #ede6cc;
						margin: 0 48px;

						svg {
							margin-top: 107px;
							fill: #12342e;
						}

						span {
							background: #00000012;
							color: #12342e;
						}
					}
				}

				@media screen and (max-width: 850px) {
					flex-direction: column;

					.trophy-item {
						&.trophy-active {
							margin: 48px 0;
						}
					}
				}
			}

			.reach-goal {
				background: #1a3f38;
				border-radius: 3px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 61px 12px 48px;
				margin-top: 70px;
				margin-bottom: 63px;
				width: 100%;

				h2 {
					margin-bottom: 15px;
					text-align: center;
				}

				p {
					margin-bottom: 40px;
					font-style: italic;
				}

				button {
					background-color: #ede6cc12;
					border-radius: 8px;
					border: none;
					color: #ede6cc;
					font-size: 18px;
					line-height: 32px;
					padding: 8px 20px;

					& + button {
						margin-left: 36px;
					}
				}

				@media screen and (max-width: 450px) {
					button {
						width: 100%;

						& + button {
							margin-left: 0;
							margin-top: 36px;
						}
					}
				}
			}
		}
	}

	.carousel-wrapper {
		position: relative;

		* {
			font-family: Lora, serif !important;
		}

		& > svg {
			position: absolute;
			transform: rotate(-110deg) scale(2);
			top: 0;
			right: 0;

			@media screen and (max-width: 767px) {
				top: 30px;
			}
		}

		.carousel-content {
			padding-top: 121px;

			.slider-single-content {
				box-shadow: none !important;
			}

			.slider-left,
			.slider-right {
				padding: 20px 44px;

				& > div {
					width: 88px;
					height: 88px;
					border: none !important;
					border-radius: 50%;
					background: #ede6cc21;
					position: relative;

					.fa {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 28px;
						font-family: 'FontAwesome' !important;
					}
				}

				@media screen and (max-width: 626px) {
					padding: 20px 20px;

					& > div {
						height: 50px;
						width: 50px;
					}
				}
			}

			.carousel-card {
				position: relative;

				img {
					width: 336px;
					height: 391px;
					object-fit: cover;
					border-radius: 7px;
				}

				span {
					position: absolute;
					top: 31px;
					left: 26px;
					width: 74px;
					height: 29px;
					background: #ffffff70;
					border-radius: 33px;
					color: #12342e;
					font-size: 14px;
					line-height: 29px;
					text-align: center;
				}

				label {
					position: absolute;
					bottom: 110px;
					left: 38px;
					font-size: 15px;
					line-height: 19px;
					color: #ffffff;
				}

				p {
					position: absolute;
					left: 38px;
					bottom: 25px;
					font-size: 22px;
					font-weight: 700;
					line-height: 32px;
					color: #ede6cc;
				}
			}

			.dashboard-button {
				margin-top: 65px;
				text-transform: uppercase;
				font-family: 'Gilroy', Arial, sans-serif !important;
			}
		}
	}

	.want-to-plant {
		background: url('../../../../assets/images/dashboard/help.png') center center;
		background-size: cover;
		width: 100%;
		padding: 75px 220px;
		margin-top: 80px;
		margin-bottom: 84px;

		.plant-wrapper {
			background: #12342e59;
			backdrop-filter: blur(29px);
			border-radius: 13px;
			padding: 60px;
			width: 100%;
			display: flex;
			align-items: flex-start;

			div {
				flex-grow: 1;
				position: relative;

				svg {
					position: absolute;
					right: 0;
					bottom: -45px;

					path {
						stroke: #ede6cc !important;
					}
				}
			}

			a {
				background: #ede6cc;
				border: none;
				border-radius: 6px;
				padding: 12px 32px;
				color: #12342e;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				font-family: 'Inter', sans-serif !important;
			}

			h2 {
				font-family: Lora, serif !important;
			}

			p {
				font-family: 'Inter', sans-serif !important;
			}
		}

		@media screen and (max-width: 1199px) {
			padding: 75px 100px;
		}

		@media screen and (max-width: 991px) {
			padding: 75px 12px;
		}

		@media screen and (max-width: 767px) {
			.plant-wrapper {
				flex-direction: column;
				align-items: center;

				svg {
					display: none;
				}
			}
		}
	}

	.dashboard-footer {
		padding: 0 84px;

		* {
			font-family: Lora, serfi !important;
		}

		.footer-content {
			border-top: 1px solid #ffffff7d;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 50px 0;

			.footer-links {
				text-align: right;
			}

			span {
				font-size: 14px;
				line-height: 32px;
			}

			svg {
				height: 27px;
				fill: #fff;
			}

			.btn-link {
				color: #ede6cc;
				text-decoration: none;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				margin-right: 50px;

				&:last-of-type {
					margin-right: 0;
				}

				@media screen and (max-width: 991px) {
					margin-right: 25px;
				}
			}

			@media screen and (max-width: 991px) {
				flex-direction: column;

				.copyright {
					text-align: center;
				}

				svg {
					margin: 30px 0;
				}

				.footer-links {
					text-align: center;
				}
			}
		}

		@media screen and (max-width: 767px) {
			padding: 0 24px;
		}
	}

	.brand-dashboard-footer {
		background: #1a3f38;
		padding-top: 60px;
		padding-bottom: 60px;
		margin-top: 80px;

		* {
			font-family: Lora, serif !important;
		}

		.social-wrapper {
			display: flex;
			margin: 28px 0;

			.social-item {
				width: 32px;
				height: 32px;
				margin-right: 16px;
				position: relative;
				background: #ddd7bf;
				border-radius: 50%;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		p {
			color: #ede6cc;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}

		.btn-link {
			text-decoration: none;
			padding: 0;
			margin-bottom: 11px;
			color: #ede6cc;
		}

		label {
			color: #ede6cc;
			font-size: 20px;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 24px;
			font-family: 'Inter', sans-serif !important;
		}

		.input-wrapper {
			position: relative;

			input {
				background: #bdb8a633;
				color: #ede6cc;
				padding: 9px 48px 9px 12px;
				font-size: 14px;
				line-height: 24px;
				font-family: 'Inter', sans-serif !important;
			}

			.btn-link {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.copyright {
		padding-top: 15px;
		padding-bottom: 15px;

		span,
		.btn-link {
			color: #ede6cc;
			font-size: 14px;
			line-height: 32px;
			text-decoration: none;
		}

		.btn-link {
			line-height: 30px;
			padding-top: 0;
			padding-bottom: 0;
		}

		a {
			margin-right: 40px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.tree-content {
		margin-top: 50px;

		img {
			width: 100%;
			margin-bottom: 40px;
		}
	}
}
