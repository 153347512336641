.payment-method {
	&__methods-item {
		border: 2px solid #f7f7f7;
		border-radius: 10px;
		padding: 19px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__methods-info {
		display: flex;
		gap: 12px;

		p {
			font-size: 12px;
			font-weight: 700;
			line-height: 1.4;
			margin: 0;
		}

		em {
			font-size: 12px;
			line-height: 1.4;
			color: #a0aec0;
			font-style: normal;
			font-weight: 700;
			display: block;

			&:last-child {
				margin-top: 5px;
				font-weight: 500;
			}

			svg {
				margin-right: 3px;
			}
		}
	}

	&__methods-btn {
		background: #f8f9fa;
		border-radius: 4px;
		font-size: 12px;
		line-height: 1.4;
		font-weight: 500;
		color: #151928;
		border: 0;

		&__methods-svg,
		&__methods-span {
			display: inline-block;
			vertical-align: middle;
		}

		&__methods-svg {
			margin-right: 3px;
		}

		&__methods-span {
			line-height: 1;
		}
	}
}
