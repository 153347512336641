.settings-layout {
	display: flex;
	width: 100%;
	height: 100%;

	.settings-main {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 55px 60px;

		h1 {
			font-size: 28px;
			line-height: 39.2px;
			color: #000000;
			margin-bottom: 0;
			font-weight: 700;
		}

		h6 {
			font-size: 12px;
			line-height: 16.8px;
			color: #a0aec0;
			margin-bottom: 40px;
			font-weight: 700;
		}

		h4 {
			font-size: 0.9rem;
			line-height: 16.8px;
			color: black;
			margin-bottom: 10px;
			font-weight: 700;
		}

		h3 {
			margin: 0;
			font-family: Gilroy;
			font-family: sans-serif;
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 1.2;
			color: rgba(0, 0, 0, 0.6);
		}

		.button-submit {
			font-size: 13px;
			line-height: 18.2px;
			padding: 10px 20px;
			background-color: #4fd1c5;
			border: none;
			border-radius: 8px;
		}

		.button-save {
			font-size: 13px;
			line-height: 18.2px;
			padding: 10px 20px;
			background-color: #a0aec0;
			border: none;
			border-radius: 8px;
		}

		.form-control {
			background-color: #ffffff;
			color: #091b3d;
			border: 1px solid #091b3d1a;
			border-radius: 8px;
		}
	}
}
