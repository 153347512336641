.nps__custom-modal {
	background-color: rgb(246, 246, 246);
	width: 100%;
	border-radius: 15px;
	height: auto;
	overflow: visible;
	max-width: 600px;

	.nps__modal-header {
		border-bottom: none;
		margin-bottom: 0;
		padding: 1rem 1.5rem 1.5rem 1.5rem;
	}

	.nps__modal-body {
		padding: 0.5rem 1.5rem 1rem 1.5rem;
		align-items: stretch;
	}

	.nps__modal-footer {
		border-top: none;
		padding: 1rem 1.5rem 1.5rem 1.5rem;
	}
}

.nps__no-resize-textarea {
	resize: none;
	width: 100%;
	height: 150px;
}

.nps__fixed-size-textarea {
	height: 150px;
	resize: none;
	overflow-y: auto;
	border-radius: 15px;
	background-color: #f7f5f5;

	&:focus {
		background-color: #f5f5f500;
	}
}

.nps__warning-text {
	color: #f00;
}

.nps__score-label {
	text-align: left;
	width: 100%;
	padding-bottom: 10px;
}
