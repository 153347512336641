.side-column {
	max-height: 400px;
	overflow-y: auto;
}

.side-column__image-container {
	height: 55px;
	width: 55px;
}

.side-column__logo svg {
	height: 40px;
	width: 40px;
}

.side-column__description {
	font-size: 12px;
	line-height: 16.8px;
	color: #000;
	letter-spacing: 0.5px;
	padding-bottom: 12.5px;
	font-style: italic;
}

.side-column__event-list {
	list-style-type: disc;
	padding-left: 15px !important;
}
.side-column__event-list-item {
	font-size: 14px;
	line-height: 16.8px;
	color: #000;
	padding-bottom: 6px;
	letter-spacing: 0.5px;
	font-weight: 400 !important;
}

.side-column__events-title {
	font-size: 20px;
	line-height: 16.8px;
	font-weight: 200 !important;
	letter-spacing: 0.75px;
}

.side-column__events-subtitle {
	letter-spacing: 0.5px;
	margin-top: 20px;
	font-size: 13px;
	line-height: 16.8px;
	color: #000;
	margin-bottom: 18px;
	font-weight: 500 !important;
}

.badge-success {
	background-color: #77cfc6;
	font-size: 10px;
	letter-spacing: 0.75px;
	padding: 4px 15px 1px 15px;
	right: 26px;
	top: 30px;
}

.badge-danger {
	background-color: rgba(181, 181, 181, 0.403);
	color: white;
	font-size: 10px;
	letter-spacing: 0.75px;
	padding: 4px 15px 1px 15px;
	right: 26px;
	top: 30px;
}
