.trees-order {
  background: #4FD1C514;
  border: 1px solid #4FD1C5;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 36px;
  width: 100%;

  label {
    color: #4FD1C5;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 35px;
  }

  .trees-count {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #4FD1C5;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.4;
      margin: 0 40px;
    }

    button {
      width: 54px;
      height: 54px;
      background: #4FD1C51A;
      border: 2px solid #FFF;
      border-radius: 50%;
      color: #4FD1C5;
      font-size: 40px;
      font-weight: 700;
      line-height: 38px;
    }
  }
}