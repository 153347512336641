/* SCSS */
.manage-account {
	width: 100%;
	margin: 50px auto;

	&__header {
		border-bottom: 1px solid #abb5c4;

		&__title {
			font-family: 'Open Sans', sans-serif;
			color: #77cfc6;
			font-weight: 600;
			text-align: center;
			width: 100%;
		}
	}

	&__body {
		justify-content: start;

		.row {
			margin-right: 0;
			margin-left: 5px;
		}

		.text-wrapper {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			line-height: 1.6;
			color: #58657a;
			max-width: 300px;
		}

		ul {
			padding: 0 0 0 20px;

			li {
				margin-top: 10px;
				margin-bottom: 6px;
			}
		}
	}

	&__footer {
		border-top: 1px solid #abb5c4;
		justify-content: space-between;
		display: flex;
		flex-direction: row;

		.btn {
			font-family: 'Open Sans', sans-serif;
			width: 40%;
			margin: auto;
			background-color: #77cfc6;
			border-color: #77cfc6;
			color: #fff;
			box-shadow: 0 2px 5px rgba(119, 207, 198, 0.5);

			&:hover {
				background-color: #5ba8a3;
			}

			&:focus {
				background-color: #77cfc6;
			}

			&.deactivate {
				background-color: #58657a;
				border-color: #58657a;

				&:hover {
					background-color: #3d4242;
				}
			}
		}
	}
}
