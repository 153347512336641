.statistic-card {
  .progress-wrapper {
    .progress-container {
      margin-bottom: 9px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .statistic-wrapper {
    margin: 24px 0;

    p {
      margin-bottom: 5px;
      padding-left: 24px;
      position: relative;
      color: #3F3F3F;
      font-weight: 600;
      line-height: 32px;

      &:before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #FFAF67;
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
      }
    }
  }
}