// .active-project-card {
// 	display: flex;
// 	flex-direction: column;
// 	background-color: rgb(250, 250, 250);
// 	border-radius: 5px;
// 	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
// 	width: 100%;
// 	height: 360px;
// 	margin-bottom: 30px;
// 	overflow: hidden;
// 	position: relative;
// 	margin-top: 30px;
// 	&:hover {
// 		cursor: pointer;
// 	}
// }

// .active-project-card__title {
// 	font-size: 32px;
// 	font-weight: bold;
// 	color: #77cfc6;
// 	padding-top: 8px;
// 	padding-left: 30px;
// }

// .active-project-card__badge {
// 	position: absolute;
// 	top: 19px;
// 	right: 30px;
// 	padding: 5px 10px;
// 	font-size: 10px;
// 	font-weight: bold;
// 	text-transform: uppercase;
// 	border-radius: 5px;
// }

// .active-project-card__badge--active {
// 	background-color: #77cfc6;
// 	color: #ffffff;
// 	font-size: 13px;
// 	font-weight: 600;
// 	letter-spacing: 0.75px;
// 	padding: 4px 15px 1px 15px;
// 	position: absolute;
// 	right: 26px;
// 	text-transform: uppercase;
// 	top: 20px;
// 	border-radius: 20px;
// }

.active-project-card__badge--inactive {
	background-color: #f8f9fa;
	color: #000;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.75px;
	padding: 4px 15px 1px 15px;
	position: absolute;
	right: 26px;
	text-transform: uppercase;
	top: 20px;
	border-radius: 5px;
	border-color: #f8f9fa;
}

.active-project-card__image-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-top: 5px;
}

.active-project-card__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.active-project-card__content {
	margin: 10px;
	margin-left: 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.active-project-card__header {
	font-size: 18px;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);
	margin: 0 0 10px 0;
	width: 80%;
}

.active-project-card__text {
	font-size: 14px;
	color: #000;
	width: 75%;
}

.active-project-card__additional-text {
	font-size: 14px;
	color: #284b45;
	margin: 0;
	text-align: right;
	width: 80%;
}

.active-project-card__button {
	position: absolute;
	bottom: 20px;
	right: 30px;
	padding: 5px 10px;
	font-size: 16px;
	text-transform: uppercase;
	border-radius: 5px;
	background-color: #ffffff;
	color: rgba(0, 0, 0, 0.65);
	border-color: #77cfc6;
	box-shadow: none;
	border-style: solid;
	border-width: 2px;
}

.active-project-card__button:hover {
	background-color: #77cfc6;
	color: #ffffff;
	opacity: 80%;
	border-color: #ffffff;
}

.custom-button-class {
	border-color: #77cfc6;
}

@media (max-width: 750px) {
	.active-project-card__text {
		margin-bottom: 50px;
	}
}

@media (max-width: 700px) {
	.active-project-card {
		display: flex;
		flex-direction: column;
		background-color: rgb(250, 250, 250);
		border-radius: 5px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 380px;
		margin-bottom: 30px;
		overflow: hidden;
		position: relative;
		margin-top: 30px;
	}

	.active-project-card__title {
		font-size: 32px;
		font-weight: bold;
		color: #77cfc6;
		padding-top: 8px;
		padding-left: 30px;
	}

	.active-project-card__badge {
		position: absolute;
		top: 19px;
		right: 30px;
		padding: 5px 10px;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 5px;
	}

	.active-project-card__badge--active {
		background-color: #77cfc6;
		color: #ffffff;
		font-size: 13px;
		font-weight: 600;
		letter-spacing: 0.75px;
		padding: 4px 15px 1px 15px;
		position: absolute;
		right: 26px;
		text-transform: uppercase;
		top: 20px;
		border-radius: 20px;
	}

	.active-project-card__badge--inactive {
		background-color: #f8f9fa;
		color: #000;
		font-size: 13px;
		font-weight: 600;
		letter-spacing: 0.75px;
		padding: 4px 15px 1px 15px;
		position: absolute;
		right: 26px;
		text-transform: uppercase;
		top: 20px;
		border-radius: 5px;
		border-color: #f8f9fa;
	}

	.active-project-card__image-container {
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding-top: 5px;
	}

	.active-project-card__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.active-project-card__content {
		margin: 10px;
		margin-left: 20px;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
	}

	.active-project-card__header {
		font-size: 18px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.65);
		margin: 0 0 10px 0;
		width: 100%;
	}

	.active-project-card__text {
		font-size: 12px;
		color: #000;
		width: 100%;
	}

	.active-project-card__button-container {
		display: flex;
		padding-top: 10px;
	}

	.active-project-card__additional-text {
		font-size: 14px;
		color: #284b45;
		margin: 0;
		text-align: right;
		width: 80%;
	}

	.active-project-card__button {
		position: absolute;
		bottom: 20px;
		right: 30px;
		padding: 5px 10px;
		margin-top: 5px;
		font-size: 16px;
		text-transform: uppercase;
		border-radius: 5px;
		background-color: #ffffff;
		color: rgba(0, 0, 0, 0.65);
		border-color: #77cfc6;
		box-shadow: none;
		border-style: solid;
		border-width: 2px;
	}

	.active-project-card__button:hover {
		background-color: #77cfc6;
		color: #ffffff;
		opacity: 80%;
		border-color: #ffffff;
	}

	.custom-button-class {
		border-color: #77cfc6;
	}
}

@media (max-width: 414px) {
	.active-project-card__text {
		font-size: 10px;
		color: #000;
		width: 100%;
	}
	.active-project-card__button {
		margin-right: 50px;
	}
	.active-project-card__badge {
		font-size: 11px;
	}
}

@media (max-width: 390px) {
	.active-project-card__text {
		font-size: 10px;
		color: #000;
		width: 100%;
	}
	.active-project-card__button {
		margin-right: 40px;
	}
	.active-project-card__badge {
		font-size: 11px;
	}
}

@media (max-width: 390px) {
	.active-project-card__text {
		font-size: 10px;
		color: #000;
		width: 100%;
	}
	.active-project-card__button {
		margin-right: 30px;
	}
	.active-project-card__badge {
		font-size: 11px;
	}
}
