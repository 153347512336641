.password-container {
  max-width: 520px;
  width: 100%;
  margin: 50px auto 50px;
  display: flex;
  flex-flow: column;

  .password-form {
    position: relative;

    .show-password {
      top: 27px;
      bottom: 0;
      right: 20px;
      display: flex;
      position: absolute;
      margin-bottom: 0;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
      }
    }
  }

  .links-group {
    margin-top: 50px;

    .outside-link {
      display: flex;
      padding: 16px 0;
      color: #000000;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid #F1F0F4;
      justify-content: space-between;

      p {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 16.8px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
