.success-modal__image {
	display: flex;
	align-items: center;
	justify-content: center;
}

.success-modal__connecting {
	display: inline-block;
	position: relative;
	padding: 30px;

	.success-modal__connecting-wrapper {
		border: 10px solid #4fd1c50f;
		border-radius: 50%;
		padding: 20px;

		.success-modal__connecting-content {
			background: #f1fbfa5c;
			border: 12px solid #4fd1c51f;
			border-radius: 40%;
			width: 110px;
			height: 110px;
			position: relative;

			svg {
				fill: #4fd1c5;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@media screen and (max-width: 529px) {
				width: 150px;
				height: 150px;
			}
		}
	}

	.success-modal__connecting-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: 10px solid transparent;
		border-bottom-color: #4fd1c512;
		border-top-color: #4fd1c512;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotate 5s linear infinite;
	}
}

.text-success-modal {
	color: #77cfc6 !important;
	font-size: 35px !important;
}

.text-light-grey.fs-6 {
	color: rgba(181, 181, 181, 0.679);
	max-width: 60%;
	margin: 0 auto;
	text-align: justify center;
	font-style: italic;
	letter-spacing: 1px;
	padding-top: 20px;
}

.success-modal__container {
	h1 {
		padding-top: 5px;
	}

	p {
		padding-top: 20px;
	}
}
