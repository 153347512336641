.billing-container {
	width: 100%;
	margin: 50px auto;

	.billing-info {
		display: flex;
		width: auto;
		max-width: none;
		margin: -8px;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		.current-plan,
		.payment-method {
			flex: 1;
			margin: 8px;
			background: #ffffff;
			border: 2px solid #f7f7f7;
			border-radius: 10px;
			padding: 26px;
			position: relative;

			h2 {
				font-size: 18px;
				font-weight: 700;
				line-height: 1.4;
				margin: 0;
			}

			h6 {
				font-size: 12px;
				font-weight: 700;
				line-height: 1.4;
				margin-bottom: 20px;
			}

			.status {
				display: block;
				position: absolute;
				width: 70px;
				height: 18px;
				right: 26px;
				top: 30px;
				background: #4fd1c5;
				border-radius: 100px;
				font-size: 12px;
				color: #fff;
				text-align: center;
				line-height: 18px;

				&--off {
					background: lightslategrey;
				}
			}
		}

		.current-plan {
			.plans {
				display: flex;

				.item {
					margin-bottom: 18px;

					&:not(:first-child) {
						margin-left: 53px;
					}

					span {
						font-size: 40px;
						color: #000;
						font-weight: 500;
					}

					em {
						font-size: 12px;
						font-weight: 700;
						font-style: normal;
						color: #a0aec0;
					}
				}
			}

			.actions {
				margin: 0 -27px -26px;
				border-top: 2px solid #f7f7f7;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px 0;

				button {
					background-color: transparent;
					color: #e33d3d;
					border: 0;
					font-size: 12px;
					font-weight: 500;
					height: auto;
					width: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;

					svg {
						margin-right: 3px;
					}
				}
			}
		}

		.payment-method {
			.methods {
				.item {
					border: 2px solid #f7f7f7;
					border-radius: 10px;
					padding: 19px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.info {
						display: flex;
						gap: 12px;

						p {
							font-size: 12px;
							font-weight: 700;
							line-height: 1.4;
							margin: 0;
						}

						em {
							font-size: 12px;
							line-height: 1.4;
							color: #a0aec0;
							font-style: normal;
							font-weight: 700;
							display: block;

							&:last-child {
								margin-top: 5px;
								font-weight: 500;
							}

							svg {
								margin-right: 3px;
							}
						}
					}

					button {
						background: #f8f9fa;
						border-radius: 4px;
						font-size: 12px;
						line-height: 1.4;
						font-weight: 500;
						color: #151928;
						border: 0;

						svg,
						span {
							display: inline-block;
							vertical-align: middle;
						}

						svg {
							margin-right: 3px;
						}

						span {
							line-height: 1;
						}
					}
				}
			}
		}
	}

	.invoice-payments {
		margin: 50px 0;

		h2 {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 5px;
			line-height: 1.4;
		}

		h6 {
			font-size: 12px;
			font-weight: 700;
			margin-bottom: 30px;
			line-height: 1.4;
		}

		.invoice-payments-table {
			border-radius: 10px 10px 0 0;
			overflow: hidden;
		}

		.table {
			border-color: #f7f7f7;

			a {
				color: inherit;
				text-decoration: none;

				> * {
					display: inline-block;
					vertical-align: middle;
				}

				svg {
					margin-right: 3px;
				}
			}

			thead {
				background-color: #151928;
				color: #fff;
				text-align: center;
				font-size: 12px;
				line-height: 1.4;
				font-weight: 700;
				border-color: #151928;

				th {
					padding: 15px 10px;
				}
			}

			tbody {
				td {
					font-size: 12px;
					line-height: 1.4;
					font-weight: 700;
					text-align: center;
					padding: 15px 10px;

					.status {
						&.paid {
							color: #4fd1c5;
						}
					}
				}
			}
		}
	}
}
