.config-info {
  width: calc(100% - 24px);
  max-width: 700px;

  .input-limit {
    position: relative;
    text-align: left;

    .form-label.limit-label {
      position: absolute;
      width: auto !important;
      bottom: 0px;
      right: 10px;
      font-weight: 400;
      font-size: 12px;
    }
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      &:first-of-type {
        color: #2D3748;
        font-size: 28px;
        font-weight: 700;
        line-height: 39px;
        margin-right: 28px;
      }

      &:last-of-type {
        color: #959595;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
      }
    }

    @media screen and (max-width: 529px) {
      flex-direction: column;

      span {
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .file-input {
    height: 120px;

    label {
      width: 200px;
      margin: auto;
    }
  }

  .business-url {
    h2 {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 6px;
    }

    span {
      color: #959595;
      display: inline-block;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 36px;
    }
  }

  .action-buttons {
    margin-top: 40px;
  }
}