.settings-nav {
  display: flex;
  flex-wrap: nowrap;

  .breadcrumb {
    align-items: center;
    margin-bottom: 0;
    padding: 9.5px 0;

    svg, a {
      margin-right: 10px;
    }

    a, p {
      font-size: 12px;
      line-height: 16.8px;
      color: #151928;
      font-weight: 700;
      margin-bottom: 0;
      text-decoration: none;

      &.home {
        margin-right: 0;
      }
    }
  }

  .setting-navbar {
    justify-content: flex-end;
    flex-grow: 1;

    .nav-link {
      padding: 8px 0;
      font-size: 14px;
      line-height: 19.6px;
      color: #A0AEC0;
      font-weight: 700;
      position: relative;
      text-transform: uppercase;

      &.active {
        color: #000000;
        margin: 0 78px;

        &::before {
          content: '';
          background-color: #4FD1C5;
          border-radius: 50%;
          bottom: 0;
          height: 9px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 9px;
        }

        @media (max-width: 1200px) {
          margin: 0 38px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.mobile-settings-nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .btn-toggle {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1);
    background-color: #ffffff;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }

  @media screen and (max-width: 991px) {
    display: flex;
  }
}
