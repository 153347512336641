.reactivation {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	max-width: 80%;
	max-height: 80%;

	&__overlay {
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 100;
	}

	&__content {
		&-btn {
			background-color: #1a3f38;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		}

		&-btn-close {
			background-color: #c51f1f;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		}

		&-btn:hover {
			background-color: #1a3f38;
		}
	}
}
