.nps-page {
	background-color: #f5f5f5;
}

.nps-container {
	.banner {
		background: url('../../../../assets/images/admin/banner.png') center center;
		background-size: cover;
		border-radius: 0 0 15px 15px;
		width: 100%;
		height: 138px;
		top: 0;
	}

	.header {
		margin-top: -36.5px;
	}

	.nps-info {
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 100px 0 48px;
	}
}

.col-container {
	background-color: white;

	padding: 20px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 15px;
	margin-right: 30px;

	&:last-child {
		margin-right: 0;
	}
}

.center-cols {
	display: flex;
	justify-content: center;
	margin-left: 5px;
	margin-right: 6px;
}
