.shopify-url-form {
	width: 100%;
	letter-spacing: 0.5px;
	padding-bottom: 15px;

	.form-label {
		color: black;
		font-size: 0.85rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding-bottom: 5px;
	}

	.input-group-text {
		color: black;
		background-color: rgba(242, 240, 240, 0.699);
		border: none;
		font-weight: 100;
		font-size: 0.75rem;
	}

	.form-control {
		border: none;
		font-size: 0.75rem;

		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(119, 207, 198, 0.5);
		}

		&::placeholder {
			color: black;
		}
	}

	.action-buttons {
		display: flex;
		justify-content: flex-end;

		.button {
			background-color: #77cfc6;
			color: white;
			border: none;
			border-radius: 50px;

			&.full-width-button {
				width: 100%;
				height: 30px;
				padding: 5px 15px;
				font-size: 12px;
			}

			&:hover {
				background-color: darken(#77cfc6, 10%);
			}

			&:active,
			&:focus {
				background-color: darken(#77cfc6, 20%);
				box-shadow: none;
			}

			&.button-secondary {
				background-color: rgba(242, 240, 240, 0.699);
				color: #77cfc6;

				&:hover {
					background-color: darken(rgba(242, 240, 240, 0.699), 10%);
				}

				&:active,
				&:focus {
					background-color: darken(rgba(242, 240, 240, 0.699), 20%);
					box-shadow: none;
				}
			}
		}
	}
}
