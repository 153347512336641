.setup-container {
  width: 100%;
  min-height: 100%;
  position: relative;

  .banner {
    background: url("../../../assets/images/setup/banner.png") center center;
    background-size: cover;
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 130px;
    top: 0;
  }

  .header {
    margin-top: -36.5px;
  }

  .setup-info {
    text-align: center;
    padding: 100px 0 48px;

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        margin-right: 24px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      @media screen and (max-width: 529px) {
        flex-direction: column;

        .button {
          margin-right: 0;
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .banner {
      height: 100px;
    }

    .header {
      margin-top: -41.5px;
    }

    .setup-info {
      padding: 96px 0 32px;
    }
  }

  .content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    svg {
      margin-right: 12px;
    }

    span {
      color: #2D3748;
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
    }
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }

  .connecting {
    display: inline-block;
    margin-top: 60px;
    position: relative;
    padding: 25px;

    .connecting-wrapper {
      border: 10px solid #4FD1C50F;
      border-radius: 50%;
      padding: 15px;

      .connecting-content {
        background: #F1FBFA5C;
        border: 18px solid #4FD1C51F;
        border-radius: 50%;
        width: 220px;
        height: 220px;
        position: relative;

        svg {
          fill: #4FD1C5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 529px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .connecting-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 10px solid transparent;
      border-bottom-color: #4FD1C512;
      border-top-color: #4FD1C512;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotate 3s linear infinite;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
