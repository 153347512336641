@mixin Draft-Base {
	background: #f8f8f8;
	border: none;
	font-size: 14px;
	line-height: 1.4;
	padding: 15px 20px;
}

.project-editor {
	:global {
		.DraftEditor-root {
			@include Draft-Base;
			padding-left: 0;
			padding-right: 8px;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;

			max-height: 300;
		}

		.public-DraftEditor-content {
			max-height: 50vh;
			min-height: 300px;
			padding: 0px 20px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				border-radius: 4px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: darkgrey;
				border-radius: 4px;
			}
		}

		.DraftEditor-toolbar {
			@include Draft-Base;
			padding: 0;
			padding-top: 8px;
			padding-left: 8px;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}
	}
}
