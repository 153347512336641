.cstatus-card {
  padding: 24px 24px !important;
  margin-top: 10px;
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }

  label {
    color: #FFF;
    font-size: 14px;
  }

  p {
    margin-bottom: 0;
    font-size: 24px;
    color: #FFF;
  }

  div {
    margin-left: 15px;
  }
}