.partner-dashboard {
  width: 100%;
  min-height: 100%;
  background: #ece6cf;
  position: relative;
  overflow-x: hidden;

  .leaf {
    position: absolute;
    right: 20px;
    top: 50px;
    max-width: 400px;

    @media screen and (max-width: 991px) {
      max-width: 300px;
    }
  }

  .z-1 {
    z-index: 1;
  }

  .dashboard-header {
    position: absolute;
    width: 100%;
    padding: 24px 40px;
    display: flex;
    z-index: 9;

    * {
      font-family: 'Inter', sans-serif !important;
    }

    .navbar {
      width: 100%;

      .navbar-brand {
        svg {
          width: 131px;
          fill: #1B4039;
        }
      }

      .navbar-toggler {
        border-color: transparent;
      }

      .navbar-nav {
        .nav-link {
          color: #1B4039;
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          padding: 12px 8px;
          position: relative;
          margin: 0 8px;

          &.public-dash {
            background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
            border: 1.5px solid #FFFFFF;
            border-radius: 15px;
            padding: 12px 32px;
          }

          &.active {
            &::after {
              content: '';
              position: absolute;
              width: 11px;
              height: 11px;
              border-radius: 50%;
              background-color: #11312B;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      @media screen and (max-width: 991px) {
        background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
        padding: 0.5rem;
        border-radius: 5px;

        .navbar-brand svg {
          fill: #1B4039;
        }

        .navbar-nav {
          border-top: 1px solid #FFF;
          margin-top: 12px;

          .nav-link {
            &:last-of-type {
              background: transparent;
              border: none;
              border-radius: 0;
              padding: 12px 8px;
            }

            &.active {
              &::after {
                top: calc((100% - 11px) / 2);
                left: -2px;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      position: fixed;
    }

    @media screen and (max-width: 575px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .dashboard-content {
    color: #11312b;
    margin-bottom: 100px;

    .content-header {
      h1 {
        font-size: 64px;
        font-weight: 700;
        line-height: 84px;
        margin-bottom: 30px;
        margin-top: 200px;
        font-weight: 500;
        font-family: Lora, serif !important;
      }

      p {
        margin-bottom: 50px;
        font-size: 20px;
        max-width: 1050px;
      }

      h5 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 50px;
      }
    }
  }

  .package-container {
    @media screen and (max-width: 991px) {
      padding: 0 15px;
    }

    .package-item {
      padding: 40px;
      border-radius: 50px;
      background-color: #d7d4bf;

      @media screen and (max-width: 991px) {
        padding: 40px 15px;
      }
    }

    .package-description {
      padding-left: 40px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      h3 {
        font-family: Lora, serif !important;
        font-weight: 500;
        font-size: 34px;
        margin-bottom: 24px;
      }

      p {
        font-size: 20px;
      }

      .btn-link {
        background-color: #11312b;
        color: #FFFFFF;
        text-decoration: none;
        font-size: 20px;
        font-weight: 700;
        padding: 15px 40px;
        border-radius: 30px;
        margin-top: 20px;
      }

      @media screen and (max-width: 991px) {
        margin-top: 30px;
        padding-left: calc(var(--bs-gutter-x) * .5);
      }
    }

    .package-img {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      .package-logo {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        img {
          max-width: 300px;
        }

        .certfied {
          max-width: 170px;

          @media screen and (max-width: 991px) {
            max-width: 100px;
          }
        }
      }
    }
  }
}

.planet-dashboard {
  width: 100%;
  min-height: 100%;
  background: #11312b;
  position: relative;
  overflow-x: hidden;

  .planet-content {
    margin-bottom: 100px;
    color: #FFFFFF;

    .content-header {
      .title-wrapper {
        align-items: baseline;
        margin-top: 100px;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 64px;
        font-weight: 700;
        line-height: 84px;
        font-weight: 500;
        font-family: Lora, serif !important;
      }

      .tree-set {
        max-height: 130px;

        @media (max-width: 1200px) {
          display: none;
        }
      }

      p {
        margin-bottom: 50px;
        font-size: 20px;
        max-width: 1050px;
      }

      h5 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 50px;
      }
    }

    .our-partners {
      padding: 40px 60px;

      .planet-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        padding: 0;
        border: 1px solid #0000009c;
      }

      & > div {
        z-index: 1;
        margin-bottom: 24px;
      }

      .partner-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        overflow: hidden;

        .item-header {
          min-height: 150px;
          display: flex;
          flex-flow: column;
          align-items: center;
          background: #ffffff54;
          padding: 15px 30px;
          justify-content: flex-end;

          img {
            margin-bottom: 15px;
            max-width: 100%;
          }

          .partner-badge {
            min-width: 150px;
          }
        }

        .partner-badge {
          background: #11312B;
          border-radius: 44px;
          color: #FFFFFF;
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          padding: 2px 16px;
          text-decoration: none;
          font-family: Lora, serif !important;
          border: none;
        }

        .item-content {
          display: flex;
          flex-flow: column;
          background: #ffffffc7;
          border-radius: 0 0 3px 3px;
          padding: 22px 30px;
          flex-grow: 1;
          justify-content: space-between;
          min-height: 230px;

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: #11312B;
          }
        }
      }

      @media (max-width: 575px) {
        margin: 0 15px;
        padding: 40px 15px;
      }
    }
  }

  .dashboard-footer {
    background: #1A3F38;
    padding-top: 60px;
    padding-bottom: 60px;

    .social-wrapper {
      display: flex;
      margin: 28px 0;

      .social-item {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        position: relative;
        background: #DDD7BF;
        border-radius: 50%;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    p {
      color: #EDE6CC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-family: Lora, serif !important;
    }

    .btn-link {
      text-decoration: none;
      padding: 0;
      margin-bottom: 11px;
      color: #EDE6CC;
      font-family: Lora, serif !important;
    }

    label {
      color: #EDE6CC;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 24px;
      font-family: 'Inter', sans-serif !important;
    }

    .input-wrapper {
      position: relative;

      input {
        background: #BDB8A633;
        color: #EDE6CC;
        padding: 9px 48px 9px 12px;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Inter', sans-serif !important;
      }

      .btn-link {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .copyright {
    padding-top: 15px;
    padding-bottom: 15px;

    span {
      font-family: Lora, serif !important;
    }

    span, .btn-link {
      color: #EDE6CC;
      font-size: 14px;
      line-height: 32px;
      text-decoration: none;
    }

    .btn-link {
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }

    a {
      margin-right: 40px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
