.auth-container {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  margin: 0 auto;

  & > .row {
    height: 100%;
  }

  .content {
    display: flex;
    justify-content: center;

    .content-wrapper {
      width: 100%;
      max-width: 550px;

      svg {
        height: 45px;
        margin-bottom: 85px;
      }

      h1 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.3;
        margin-bottom: 65px;
      }

      form {
        .form-title {
          color: #323232;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.4;
          margin-bottom: 24px;
        }
      }

      .link {
        cursor: pointer;
        color: #A0AEC0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .banner {
    background: rgb(223,239,228);
    // background: linear-gradient(45deg, rgba(223,239,228,1) 0%, rgba(206,231,215,1) 100%);
    // background-image: url("../../assets/images/auth/plantATree.svg"), linear-gradient(135deg, rgba(223,239,228,1) 0%, rgba(206,231,215,1) 100%);

    background-image: url("../../assets/images/auth/plantATree.svg"), linear-gradient(134.4deg, rgb(223, 239, 228) 0%, rgb(223, 239, 228) 49%, rgb(206, 231, 215) 49.8%, rgb(206, 231, 215) 100%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;

    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
  }

  .banner-img {
    display: none;
  }

  @media (max-width: 1080px) {
    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0!important;
      padding-left: 0!important;
      padding-right: 0!important;
      width: 100%;
    }
    .banner {
      display: none;
    }

    .banner-img {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    .banner-img img {
      width: 100%;
      height: 100%;

    }
  }
}

  .error {
    color: darkred;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }



.verify-container {
  width: 100%;
  min-height: 100%;
  position: relative;

  .banner {
    background: url("../../assets/images/setup/banner.png") center center;
    background-size: cover;
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 130px;
    top: 0;
  }

  .header {
    margin-top: -36.5px;
  }

  .verify-info {
    text-align: center;
    padding: 100px 0 48px;

    & > label {
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
      margin-bottom: 18px;
    }

    & > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 0;

      span {
        border-bottom: 1px solid;
      }
    }

    & > .get-email {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      margin-bottom: 0;
      font-style: italic;
    }
  }

  .connecting {
    display: inline-block;
    margin-top: 60px;
    position: relative;
    padding: 25px;

    .connecting-wrapper {
      border: 10px solid #4FD1C50F;
      border-radius: 50%;
      padding: 15px;

      .connecting-content {
        background: #F1FBFA5C;
        border: 18px solid #4FD1C51F;
        border-radius: 50%;
        width: 220px;
        height: 220px;
        position: relative;

        svg {
          fill: #4FD1C5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 529px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .connecting-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 10px solid transparent;
      border-bottom-color: #4FD1C512;
      border-top-color: #4FD1C512;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotate 3s linear infinite;
    }
  }

  @media screen and (max-width: 991px) {
    .banner {
      height: 100px;
    }

    .header {
      margin-top: -41.5px;
    }
  }

  .btn-resend {
    padding: 0;
    border: none;
    color: #212529;
    font-style: italic;
    box-shadow: none;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
