.virtual-forest {
  margin-top: 83px;

  * {
    font-family: Lora, serif !important;
  }

  .dashboard-button {
    text-transform: uppercase;
    font-family: "Gilroy", Arial, sans-serif !important;
  }

  h2 {
    margin-bottom: 22px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 43px;
  }

  label {
    margin-top: 5px;
    font-size: 15px;
  }

  img {
    width: 100%;
    margin-bottom: 60px;
  }

  .glb-tree {
    width: 100%;
    height: 800px;
    margin-bottom: 60px;
    --progress-bar-color: #12342Eaa;
    --poster-color: transparent;
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: 768px) {
      height: 400px;
    }
    #lazy-load-poster {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-position: center center;
      background-size: cover;
    }
  }
  .glb-tree--madagascar {
    background-image: url("../../../../assets/images/dashboard/background_3.png");
    #lazy-load-poster {
      background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    }
  }
  .glb-tree--kenya {
    background-image: url("../../../../assets/images/dashboard/background_3.png");
    #lazy-load-poster {
      background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    }
  }
  .glb-tree--thailand {
    background-image: url("https://picsum.photos/id/28/800/");
    // #lazy-load-poster {
    //   background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    // }
  }
  .glb-tree--kelp {
    background-image: url("https://picsum.photos/id/280/800/");
    // #lazy-load-poster {
    //   background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    // }
  }
  .glb-tree--michigan {
    background-image: url("https://picsum.photos/id/229/800/");
    // #lazy-load-poster {
    //   background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    // }
  }
  .glb-tree--montana {
    background-image: url("https://picsum.photos/id/140/800/");
    // #lazy-load-poster {
    //   background-image: url("../../../../assets/images/dashboard/placeholder-mangrove.png");
    // }
  }
}
