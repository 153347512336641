.project-card {
  align-items: center;
  background-color: white;
  border: 2px solid #eaeaea;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  // height: 180px;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
  width: 48%;
}

.project-card:hover {
  border: 2px solid #5fcdc2;
  .project-card__header {
    color: rgba(0, 0, 0, 0.85);
  }
  .project-card__platformName {
    color: #5fcdc2;
  }
  .project-card__platformText {
    color: rgba(0, 0, 0, 0.95);
  }
}

.project-card--disabled {
  cursor: auto;
  .project-card__header-container {
    background-color: #f8f8f8;
  }
  &:hover {
    border: 2px solid #eaeaea;
    .project-card__header {
      color: rgba(0, 0, 0, 0.85);
    }
    .project-card__platformName {
      color: #5fcdc2;
    }
    .project-card__platformText {
      color: rgba(0, 0, 0, 0.95);
    }
  }
}

.project-card__header-container {
  align-items: center;
  background-color: #f1fbfa;
  border-top: 2px solid #eaeaea;
  display: flex;
  font-size: 15px;
  height: 40px;
  justify-content: space-around;
  padding-left: 2px;
  padding-right: 2px;
  width: 100%;
}

.project-card__platformName {
  color: #5fcdc2;
  display: flex;
  font-size: 21px;
  font-weight: 600;
  justify-content: center;
  padding-bottom: 7.5px;
  margin-left: 120px;
  width: calc(100% - 120px);
}

.project-card__platformName-container {
  background-color: #eaeaea8b;
  border-bottom: 2px solid #eaeaea;
  height: 42px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 6px;
  padding-top: 5px;
  width: 100%;
}

.project-card__platformTextBox {
  display: flex;
  margin-left: 120px;
  padding: 4px 1px 0 1px;
  width: calc(100% - 120px);
}

.project-card__platformText {
  color: #000;
  font-size: 12px;
  letter-spacing: 0.75px;
  margin: 1px;
  max-height: 145px;
  overflow: hidden;
  padding-left: 3px;
  text-align: left;
  text-overflow: clip;
  width: 100%;
}

.project-card__text-box {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.project-card__thumbnail-container {
  background-position: center;
  background-size: cover;
  border-right: 2px solid #eaeaea;
  height: 100%;
  position: absolute;
  min-width: 120px;
  width: 120px;
}

.project-card__top-container {
  display: flex;
  min-height: 200px;
  position: relative;;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .project-card {
    width: 100%;
  }
}
