.profile-container {
  width: 100%;
  margin: 50px auto;

  @media screen and (max-width: 767px) {
    .statistic-label {
      margin-top: 50px;
    }
  }

  .profile-col {
    position: relative;
    margin-bottom: 33px;

    .profile-item-label {
      position: absolute;
      left: 29px;
      background-color: white;
      padding: 0 10px;
    }

    .profile-item-input {
      margin-top: 10px;
      padding: 15px 26px;
      border: 1px solid #09183d;
    }

    .profile-item-select {
      margin-top: 10px;
      padding: 12.5px 26px;
      border: 1px solid #09183d;
      border-radius: 8px;
    }
  }

  .statistic-item {
    background-color: #f7f7fa;
    padding: 20px 30px;
    margin-bottom: 30px;
    border: 1px solid black;
    border-radius: 15px;

    &:first-of-type {
      margin-top: 50px;
    }

    .statistic-item-label {
      font-size: 11px;
      line-height: 16.5px;
      color: #a8a8a8;
      margin-bottom: 3px;
    }

    .statistic-item-value {
      font-family: Helvetica !important;
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .statistic-item-chart {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .button-download {
    font-weight: 700;
    padding: 10px 20px;
    background-color: white;
    border: none;
    border-radius: 8px;
    color: black;
  }
}
