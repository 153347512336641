.setting-container {
  width: 100%;
  min-height: 100%;
  position: relative;

  .content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    svg {
      fill: #5E8E3E;
      margin-right: 12px;
    }

    span {
      color: #2D3748;
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
    }
  }

  .public-url {
    color: #212529;
    cursor: pointer;
    font-size: 1.2rem;
  }

  .email-link {
    color: #212529;
  }

  .input-limit {
    position: relative;
    text-align: left;

    .form-label.limit-label {
      position: absolute;
      width: auto !important;
      bottom: 0px;
      right: 10px;
      font-weight: 400;
      font-size: 12px;
    }
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }

  .connecting {
    display: inline-block;
    margin-top: 60px;
    position: relative;
    padding: 25px;

    .connecting-wrapper {
      border: 10px solid #4FD1C50F;
      border-radius: 50%;
      padding: 15px;

      .connecting-content {
        background: #F1FBFA5C;
        border: 18px solid #4FD1C51F;
        border-radius: 50%;
        width: 220px;
        height: 220px;
        position: relative;

        svg {
          fill: #4FD1C5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 529px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .connecting-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 10px solid transparent;
      border-bottom-color: #4FD1C512;
      border-top-color: #4FD1C512;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotate 3s linear infinite;
    }
  }

  .banner {
    background: url("../../../assets/images/setup/banner.png") center center;
    background-size: cover;
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 130px;
    top: 0;
  }

  .header {
    margin-top: -36.5px;
  }

  .setting-info {
    text-align: center;
    padding: 100px 0 48px;

    .setting-content {
      width: calc(100% - 24px);
      max-width: 850px;
      margin: 0 auto;
      color: #2D3748;

      h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.4;
      }

      h2 {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.4;
      }

      .input-group {
        .react-datepicker-wrapper {
          flex-grow: 1;
          width: auto;

          input {
            width: 100%;
            outline: none;

            &:focus {
              box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
            }
          }
        }

        .input-group-text {
          background: #C4C4C4;
          border: none;
          border-radius: 15px 0 0 15px;
          justify-content: center;
          width: 44px;
        }

        input {
          border: none;
          border-radius: 0 15px 15px 0;
          background: #F8F8F8;
          padding: 15px 7px;
          color: #A0AEC0;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
        }
      }

      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .button {
          &:first-of-type {
            margin-right: 12px;
          }
        }
      }

      .form-label {
        width: 100%;
        text-align: left;
      }

      .file-input {
        width: 100%;

        label {

        }
      }
    }
  }
}