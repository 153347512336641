.client-dashboard {
  .dashboard-content {
    .tree-content {
      margin-top: 100px;

      img {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}
